/**
 * Dialog for saving the searchparams
 */
import {Component, inject, Input, ViewChild} from '@angular/core';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {NgModel} from '@angular/forms';
import {ToastAlertSignalStore} from '../../store/alert/toast-alert.signal-store';
import {ToastAlert} from '../../model/toast-alert';

@Component({
  selector: 'ucs-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})

export class SaveDialogComponent {
  inputText: string;
  @Input() parent: string;
  @Input() search: UserVehicleSearchParamsRequestDto;
  @ViewChild('descriptionInput', {static: true}) descriptionInput: NgModel;

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private userService: UserService,
              private translate: TranslateService) {
  }

  // save search
  saveSettings() {
    if (this.inputText !== undefined && this.inputText.length !== 0) {
      this.search.searchName = this.inputText;
      this.search.notificationEnabled = true;
      this.userService.addSavedSearch(this.search);
      this.toastAlertStore.addToastAlert(new ToastAlert(
        'info',
        this.translate.instant('save-search.success') + ' ("' + this.inputText + '")'));
      this.inputText = '';
    }
  }

  cancelSettings() {
    this.inputText = '';
    this.descriptionInput.reset();
  }
}
