import {Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import * as VehicleMaintenanceActions from '../../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {combineLatest, filter, Subject, takeUntil, concatMap} from 'rxjs';
import {State} from '../../../store/user/user.reducers';
import {OfferService} from '../../../service/offer.service';
import {AuthenticationService} from '../../../service/authentication.service';
import * as DetailPageActions from '../../../store/detail-page/detail-page.actions';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {VehicleMaintenanceService} from '../../../service/vehicle-maintenance.service';
import {DealerService} from '../../../service/dealer.service';
import {
  UpdateEnforcedAuctionBaseVehicleAction, UpdateVehicleInListAction
} from '../../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {ucsDeepCopy, ucsIsNil} from '../../../misc/utils';
import {SpinnerService} from '../../../service/spinner.service';
import {EnforcedAuctionService} from '../../../service/enforced-auction.service';
import {first} from 'rxjs/operators';
import {CarouselLibConfig, DescriptionStrategy, Image} from '@ks89/angular-modal-gallery';
import {TranslateService} from '@ngx-translate/core';
import {RxStompService} from '../../../service/rx-stomp.service';
import {NgxFloatUiPlacements, NgxFloatUiTriggers} from 'ngx-float-ui';
import {ToastAlertSignalStore} from '../../../store/alert/toast-alert.signal-store';

/**
 * Hosts a vehicle card containing compact information on the vehicle as well as the possibility to bid/buy
 */
@Component({
  selector: 'ucs-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() vehicle: VehicleBaseDto & EnforcedAuctionVehicleBaseDto;
  @Input() activeCategory: string;
  basket: VehicleBaseDto[];
  vehicles: VehicleBaseDto[];
  viewSettings: ViewTag[];
  fuelTypesAggregation: string;
  showAllVehicles = false; // indicates whether all vehicles in a bundle are to be displayed in the vehicle card
  galleryImages: Image[] = [];
  userState: State;
  canSeeInternalNote = false;
  showLinkToOffer: boolean;
  fleetDealer: boolean;
  leasingDealer: boolean;
  leasingContractNumber: boolean;
  showExternalId = false;
  isAdminForEnforcedAuction: boolean = false;
  footerStatus: VehicleFooterStatus;
  showExternalDocumentLink = false;
  vehicleDetailUpdate: VehicleDetailUpdateDto;
  vehicleDetailDto: VehicleDetailDto;
  mayCreateOfferBuyNow: boolean;
  mayCreateOfferAuction: boolean;
  isAllowedToSeeDiscrepancies: boolean;
  discrepanciesAvailable: boolean;
  carouselConfig: CarouselLibConfig;
  wsSubscriptionOpen: boolean;
  private unsubscribe: Subject<void> = new Subject<void>();

  private bookmarkableStatus: EnforcedAuctionItemStatus[] =
    ['OFFER_FINISHED','MANUALLY_REMOVED', 'NOT_SELECTED', 'ERROR', 'OFFER_EXPIRED', 'OFFER_CANCELLED', null];

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(public store: Store<fromRoot.AppState>,
              private offerService: OfferService,
              private systemSettings: SystemSettingsService,
              private vehicleMaintenanceService: VehicleMaintenanceService,
              private enforcedAuctionService: EnforcedAuctionService,
              protected dealerService: DealerService,
              private spinnerService: SpinnerService,
              private translateService: TranslateService,
              private stompService: RxStompService) {
  }

  /**
   * On init cast the input VehicleBaseDto to the subtype for binding to template
   * Generate the aggregated text for fuel types
   */
  ngOnInit() {
    this.addImagesToGallery();
    this.initFooterStatus();
    this.initMaintenanceVehicleBasket();
    this.initViewSettings();
    this.showLinkToOffer = false;
    this.initUserState();
    this.initFeatureSettings();
    this.fleetDealer = this.vehicle.seller.fleetDealer;
    this.mayCreateOfferBuyNow = this.mayCreateOffer('BUYNOW');
    this.mayCreateOfferAuction = this.mayCreateOffer('AUCTION');

    this.systemSettings.getSystemFeatureSettingForChannel(
      'VEHICLE_MAINTENANCE.display_vehicle_list_discrepancies_enabled', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe)).subscribe(settingString => {
        if (settingString) {
          const stringValue = 'true';
          this.isAllowedToSeeDiscrepancies = stringValue === settingString;
          if (this.isAllowedToSeeDiscrepancies && this.vehicle.offerDataDto?.offerId) {
            this.checkForDiscrepancies();
          }
        }
      });
    this.setupCarouselGallery();
  }

  /**
   * Inverts bookmark for enforced vehicle boolean
   */
  onToggleEnforcedVehicleBookmark() {
    if (['AUTO_SELECTED', 'MANUALLY_SELECTED'].includes(this.vehicle.enforcedAuctionVehicleData.status)) {
      const dto: UpsertCatalogItemDto = {
        catalogItemId: this.vehicle.enforcedAuctionVehicleData.catalogItemId,
        vehicleId: this.vehicle.id,
        sourceId: this.vehicle.externalId,
        startingPrice: this.vehicle.enforcedAuctionVehicleData.startingPrice?.net,
        status: 'MANUALLY_REMOVED',
      };
      this.spinnerService.spinner(
        this.enforcedAuctionService.enforcedAuctionBookmark(dto))
        .subscribe(() => {
          this.vehicle.enforcedAuctionVehicleData.status = 'MANUALLY_REMOVED';
          let deepClone: EnforcedAuctionVehicleBaseDto = ucsDeepCopy(this.vehicle) as EnforcedAuctionVehicleBaseDto;
          deepClone.enforcedAuctionVehicleData.status = 'MANUALLY_REMOVED';
          this.store.dispatch(new UpdateEnforcedAuctionBaseVehicleAction(deepClone));
        });
    } else {
      if (this.bookmarkableStatus.includes(this.vehicle.enforcedAuctionVehicleData.status)) {
        const dto: UpsertCatalogItemDto = {
          catalogItemId: this.vehicle.enforcedAuctionVehicleData.catalogItemId,
          vehicleId: this.vehicle.id,
          sourceId: this.vehicle.externalId,
          startingPrice: this.vehicle.enforcedAuctionVehicleData.startingPrice?.net,
          status: 'MANUALLY_SELECTED',
        };
        if (this.vehicle.enforcedAuctionVehicleData.status === 'OFFER_EXPIRED') {
          dto.catalogItemId = null;
        }
        this.spinnerService.spinner(
          this.enforcedAuctionService.enforcedAuctionBookmark(dto))
          .subscribe((catalogItemId) => {
            this.vehicle.enforcedAuctionVehicleData.catalogItemId = catalogItemId;
            this.vehicle.enforcedAuctionVehicleData.status = 'MANUALLY_SELECTED';
            let deepClone: EnforcedAuctionVehicleBaseDto = ucsDeepCopy(this.vehicle) as EnforcedAuctionVehicleBaseDto;
            deepClone.enforcedAuctionVehicleData.status = 'MANUALLY_SELECTED';
            this.store.dispatch(new UpdateEnforcedAuctionBaseVehicleAction(deepClone));
          });
      }
    }
  }

  private userCanSellOffersCheck(userState: State) {
    if (AuthenticationService.canSellOffersForAnyChannel(userState) || userState.isCustodianPb || userState.isClerkPb) {
      this.showLinkToOffer = true;
    }
  }

  /**
   * We need the ngOnChanges method to ensure vehicleDetails is updated when vehicle is updated in parent
   * Otherwise Angular does not propagate changes, only directly to the input variable (vehicle)
   * @param {SimpleChanges} changes: The changes that occurred
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'vehicle') {
        if (this.vehicle) {
          this.initFuelTypesAggregation();
        }
      }
    }
  }

  /**
   * Put this vehicle for the current user to his tray
   */
  toggleTray() {
    if (!this.checkInBasket()) {
      this.store.dispatch(new VehicleMaintenanceActions.UpdateVehiclesBasketAction(this.vehicle));
    } else {
      this.store.dispatch(new VehicleMaintenanceActions.DeleteVehicleFromBasketAction(this.vehicle));
    }
  }

  /**
   * Determines whether the current vehicle is already part of the basket
   */
  checkInBasket(): VehicleBaseDto {
    const vehicleInBasket = this.basket.find(vehicle => this.vehicle.id === vehicle.id);
    return vehicleInBasket;
  }

  /**
   * Checks whether the current user has permission to create an offer for the current offer type, also taking the
   * vehicle's channel into account.
   *
   * @param type: the OfferType to check permission for
   * @returns whether the user may create an offer based on vehicle's channel and offer type
   */
  mayCreateOffer(type: OfferType): boolean {
    // if channel is ALL_UC and fleet dealer, offer creation is allowed
    return (this.fleetDealer && this.vehicle.channel === 'ALL_UC') ||
      // documents, pictures, contact person and vehicle detail must be present
      (!(this.vehicle.missingDocument
          || this.vehicle.missingPicture
          || this.vehicle.missingContactPerson
          || this.vehicle.missingVehicleDetail)
        // user needs to have permission to create offers
        && AuthenticationService.isUserAllowedToCreateOfferTypeForChannel(type, this.vehicle.channel, this.userState));
  }

  /**
   * Generates a text for display of fuelTypes
   */
  private initFuelTypesAggregation() {
    this.fuelTypesAggregation = '';

    this.vehicle.fuelTypes.forEach((fuelType, index) => {
      this.fuelTypesAggregation += fuelType;

      // if this is not the last fuelType, append a comma for separation in the string
      if (index < this.vehicle.fuelTypes.length - 1) {
        this.fuelTypesAggregation += ', ';
      }
    });
  }

  /**
   * Stores the backlink target for the offer detail page in the NgRx store
   */
  storeBacklink() {
    this.store.dispatch(new DetailPageActions.PushBacklinkAction('/maintenance/vehicle/#vehicle' + this.vehicle.id));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Use to determine the view state of a field configured by the view settings
   *
   * @param viewSettingTag view tag
   */
  view(viewSettingTag: ViewTag): boolean {
    return this.viewSettings.includes(viewSettingTag);
  }

  lockEnforcedVehicle(status: VehicleStatus) {
    if (!this.wsSubscriptionOpen) {
      this.openWsSubscription();
    }
    //We have to get the vehicleDetail since the update request requires a VehicleDetailUpdateDto
    this.vehicleMaintenanceService.getVehicle(this.vehicle.id)
      .pipe(first(vehicleDetail => !!vehicleDetail), concatMap(vehicleDetail => {
        this.vehicleDetailDto = vehicleDetail;
        this.initVehicleDetailUpdate(this.vehicleDetailDto, status);
        return this.vehicleMaintenanceService.updateVehicleMaintenanceDetails(this.vehicleDetailDto.id, this.vehicleDetailUpdate).pipe(first());
      }))
      .subscribe({
        next: () => {},
        error: () => {
          this.toastAlertStore.danger(this.translateService.instant('vehicle.enforcedAuction.lock.error'));
        }
      });
  }

  /**
   * Subscribe to WS updates delivered after elastic index was updated and
   * update single vehicle in vehicle list from store
   */
  private openWsSubscription() {
    this.wsSubscriptionOpen = true;
    this.stompService.watch('/topic/vehicle-update/' + this.vehicle.id)
      .pipe(filter(vehicleUpdate => !!vehicleUpdate), takeUntil(this.unsubscribe))
      .subscribe(updateNotification => {
        this.store.dispatch(new UpdateVehicleInListAction(this.vehicle.id, true));
      });
  }

  private initFeatureSettings() {
    this.systemSettings
      .isSystemFeatureActivatedForChannel('LEASING_DEALER', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.leasingDealer = value && !!this.vehicle.contractPartner);
    this.systemSettings
      .isSystemFeatureActivatedForChannel('LEASING_CONTRACT_NUMBER', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.leasingContractNumber = value);
    this.systemSettings
      .isSystemFeatureActivatedForChannel('SHOW_EXTERNAL_ID', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.showExternalId = value);
    this.systemSettings
      .getSystemFeatureSettingForChannel('EXTERNAL_DOCUMENT_LINK.external_document_link',
        'ALL_UC')
      .pipe(first())
      .subscribe(value => {
        if (value === 'true') {
          this.showExternalDocumentLink = true;
        }
      });
    this.systemSettings.getSystemFeatureSettingForChannel(
      'ENFORCED_AUCTION.maintenance', this.vehicle.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value && this.vehicle.channel === 'PIA') {
          let parsedEnforcedAuctionMaintenanceSettings: EnforcedAuctionMaintenanceSettings = JSON.parse(value);
          this.isAdminForEnforcedAuction = parsedEnforcedAuctionMaintenanceSettings.enabled &&
            parsedEnforcedAuctionMaintenanceSettings.canAdminister;
        }
      });
  }

  private initUserState() {
    this.store.select(fromRoot.getUserState)
      .pipe(
        filter(userState => !!userState.userInfo),
        takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
          this.userCanSellOffersCheck(userState);
          this.canSeeInternalNote = this.userState.userInfo.currentDealerId === this.vehicle.seller.id ||
            this.userState.isDevPoi ||
            this.userState.isSupportPoi ||
            this.userState.isSupportPb;
        }
      });
  }

  private initViewSettings() {
    this.store.select(fromRoot.getViewSettings)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(viewSettings => {
        if (viewSettings) {
          this.viewSettings = viewSettings;
        } else {
          this.viewSettings = <ViewTag[]>['MILEAGE', 'INITIAL-REGISTRATION', 'FUEL', 'POWER'];
        }
      });
  }

  private initMaintenanceVehicleBasket() {
    this.store.select(fromRoot.getMaintenanceVehicleBasket)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(vehicleBasket => {
        if (vehicleBasket) {
          this.basket = vehicleBasket;
        }
      });
  }

  private addImagesToGallery() {
    this.galleryImages = [];
    this.vehicle.images.forEach((image, index) => {
      if (image?.url) {
        let imageUrl = image.url;
        if (image.scalable) {
          imageUrl += '/320';
        }
        this.galleryImages.push(new Image(image.id, {
          img: imageUrl,
          fallbackImg: '/assets/icons/ucs_fallback_img.svg',
        }));
      }
    });
  }
  private initFooterStatus() {
    if (this.vehicle) {
      this.footerStatus = this.vehicleMaintenanceService.deriveVehicleFooterStatusFromVehicle(this.vehicle);
    }
  }

  private initVehicleDetailUpdate(vehicleDetailDto: VehicleDetailDto, status: VehicleStatus) {
    this.vehicleDetailUpdate = {} as VehicleDetailUpdateDto;
    this.vehicleDetailUpdate.status = status;
    this.vehicleDetailUpdate.co2Emission = vehicleDetailDto.co2Emission;
    this.vehicleDetailUpdate.extraTax = vehicleDetailDto.extraTax;
    this.vehicleDetailUpdate.vatType = vehicleDetailDto.vatType.data;
    this.vehicleDetailUpdate.consumption = vehicleDetailDto.consumption;
    this.vehicleDetailUpdate.exploitationType = vehicleDetailDto.exploitationType.data;
    this.vehicleDetailUpdate.infoText = vehicleDetailDto.infoText;
    this.vehicleDetailUpdate.mileage = vehicleDetailDto.mileage;
    this.vehicleDetailUpdate.emissionClass = vehicleDetailDto.emissionClass;
    this.vehicleDetailUpdate.noxEA189Affected = vehicleDetailDto.noxEA189Affected;
    this.vehicleDetailUpdate.thermalWindowAffected = vehicleDetailDto.thermalWindowAffected;
    if (this.vehicleDetailDto.seller.contactPerson) {
      this.vehicleDetailUpdate.contactPersonId = vehicleDetailDto.seller.contactPerson.id;
    }
    this.vehicleDetailUpdate.holderCount = vehicleDetailDto.holderCount;
    if (this.vehicleDetailDto.coloring) {
      this.vehicleDetailUpdate.colorCode = vehicleDetailDto.coloring.colorCode;
    }
    this.vehicleDetailUpdate.seats = vehicleDetailDto.seats;
    this.vehicleDetailUpdate.custodyDealer = vehicleDetailDto.dealerInformation;
    this.vehicleDetailUpdate.contractSpecialType = vehicleDetailDto.contractSpecialType;
    this.vehicleDetailUpdate.licensePlate = vehicleDetailDto.licensePlate;
    this.fleetDealer = vehicleDetailDto.seller.fleetDealer;
  }

  checkForDiscrepancies() {
    if (['FINISHED', 'EXPIRED', 'CANCELLED', 'DELETED']
      .includes(this.vehicle.offerDataDto?.offerStatus) ||
      !this.isOfferDataOlderThanVehicleData(this.vehicle?.modified, this.vehicle?.offerDataDto?.modified)) {
      this.discrepanciesAvailable = false;
      return;
    }
    let offerObservable = this.offerService.getOfferCheckForDiscrepancy(this.vehicle.offerDataDto.offerId);
    let vehicleDetailObservable = this.vehicleMaintenanceService.getVehicle(this.vehicle.id);
    combineLatest([offerObservable, vehicleDetailObservable]).pipe(first())
      .subscribe(([offer, vehicleDetail]) => {
        this.compareOfferAndVehicleData(<VehicleItemDetailDto>offer.items[0], vehicleDetail);
      });
  }

  isOfferDataOlderThanVehicleData(vehicleModified: any, offerModified: any) {
    if (ucsIsNil(vehicleModified) || ucsIsNil(offerModified)) {
      return false;
    }

    const parsedVehicleModified = new Date(vehicleModified);
    const parsedOfferModified = new Date(offerModified);

    if (isNaN(parsedVehicleModified.getTime()) || (isNaN(parsedOfferModified.getTime()))) {
      return false;
    }

    return parsedVehicleModified > parsedOfferModified;
  }

  compareOfferAndVehicleData(offerItem: VehicleItemDetailDto, vehicleDetail: VehicleDetailDto) {
    this.discrepanciesAvailable = vehicleDetail.exploitationType.data !== offerItem.exploitationType.data ||
      vehicleDetail.mileage !== offerItem.mileage || vehicleDetail.combinedFuelCode !== offerItem.combinedFuelCode ||
      vehicleDetail.extraTax?.paid !== offerItem.extraTax?.paid || vehicleDetail.extraTax?.amount !== offerItem.extraTax?.amount ||
      vehicleDetail.extraTax?.rate !== offerItem.extraTax?.rate || vehicleDetail.extraTax?.refund !== offerItem.extraTax?.refund ||
      vehicleDetail.motorCapacity !== offerItem.motorCapacity ||
      vehicleDetail.powerPs !== offerItem.powerPs || vehicleDetail.powerKw !== offerItem.powerKw ||
      vehicleDetail.gear !== offerItem.gear || vehicleDetail.drive !== offerItem.drive ||
      vehicleDetail.doors !== offerItem.doors || vehicleDetail.vatType?.data !== offerItem.vatType?.data ||
      vehicleDetail.consumption !== offerItem.consumption || vehicleDetail.co2Emission !== offerItem.co2Emission ||
      vehicleDetail.emissionClass !== offerItem.emissionClass || vehicleDetail.initialRegistrationDate !== offerItem.initialRegistrationDate ||
      vehicleDetail.holderCount !== offerItem.holderCount || vehicleDetail.newCarColorCode !== offerItem.newCarColorCode ||
      vehicleDetail.seats !== offerItem.seats ||
      vehicleDetail.weightNet !== offerItem.weightNet || vehicleDetail.weightGross !== offerItem.weightGross ||
      vehicleDetail.modelCode !== offerItem.modelCode || vehicleDetail.euroTaxCode !== offerItem.euroTaxCode ||
      vehicleDetail.chassis !== offerItem.chassis || vehicleDetail.vin !== offerItem.vin || vehicleDetail.coloring?.colorText !== offerItem?.color;
  }

  private setupCarouselGallery() {
    this.carouselConfig = {
      carouselImageConfig: {
        description: {
          strategy: DescriptionStrategy.ALWAYS_HIDDEN
        }
      },
      carouselDotsConfig: {
        visible: false
      },
      carouselPreviewsConfig: {
        visible: false
      },
      carouselPlayConfig: {
        autoPlay: false,
        interval: 3000,
        pauseOnHover: true
      }
    };
  }

  sortNotesByMostRecent(vehicle: VehicleBaseDto & EnforcedAuctionVehicleBaseDto) {
    return vehicle.notes.sort((a, b) => {
      const timeA = a.modified ? new Date(a.modified).getTime() : new Date(a.created).getTime();
      const timeB = b.modified ? new Date(b.modified).getTime() : new Date(b.created).getTime();
      return timeB - timeA;
    })[0];
  }

  protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
  protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
