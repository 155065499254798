import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,} from '@angular/core';
import {OfferService} from '../../../service/offer.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {
  getUserSubstitutions,
} from '../../../store/app.reducers';

@Component({
  selector: 'ucs-expiring-offer-calendar',
  templateUrl: './expiring-offer-calendar.component.html',
  styleUrls: ['./expiring-offer-calendar.component.scss']
})
export class ExpiringOfferCalendarComponent implements OnInit, OnDestroy, OnChanges {

  expirationsPerDay: { [index: string]: number } = {};
  activeAuctionOffers: number;
  activeBuyNowOffers: number;
  private unsubscribe: Subject<void> = new Subject<void>();
  @Input() currentChannel: DistributionChannel;

  constructor(private offerService: OfferService,
              private store: Store<fromRoot.AppState>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'currentChannel') {
        this.getExpiringOffersCount();
      }
    }
  }

  ngOnInit(): void {

    this.getExpiringOffersCount();

    this.store.select(getUserSubstitutions).pipe(takeUntil(this.unsubscribe))
      .subscribe(substitutions => {
        if(substitutions && this.currentChannel && this.currentChannel === 'PB') {
          this.getExpiringOffersCount();
        }
      });
  }

  getNumOfExpiringOffers(date: any): number {
    const formattedDate = `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
    return this.expirationsPerDay[formattedDate] || 0;
  }

  getExpiringOffersCount() {
    this.offerService.getExpiringOffersCount(this.currentChannel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.expirationsPerDay = data.expirationsPerDay;
          this.activeAuctionOffers = data.totalActiveAuctions;
          this.activeBuyNowOffers = data.totalActiveBuyNowOffers;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
