<div class="countdown w-100 {{ showBidBuyButtons ? 'text-lg-end text-md-center float-lg-end' : '' }}
{{ footerStatus === 'AUCTION_ACTIVE' || (footerStatus === 'AUCTION_EXTENDED' && hideFooterStatus) ? 'mb-2' : '' }}
{{ offerType === 'BUYNOW' ? 'invisible': '' }}">
  @if (showCountdown) {
    <div>
      <ucs-countdown [expirationDate]="expirationDate" [small]="true"></ucs-countdown>
    </div>
  }
</div>
<div [ngClass]="{'float-md-end': showBidBuyButtons}">

  @if (userState.isCustodianPb
    && ['FOLLOWUP_PROPOSAL_NEW', 'FOLLOWUP_PROPOSAL_ACCEPTED','FOLLOWUP_PROPOSAL_REJECTED' ].includes(footerStatus)) {
    <a
      class="offer-link cursor-pointer"
      [routerLink]="'/maintenance/vehicle/' + vehicle.id"
      [fragment]="'followup-section'">
      <strong>{{('vehicle-footer.' + footerStatus) | translate}}</strong>
    </a>
  } @else {
    @if (footerStatus !== 'AUCTION_ACTIVE' && ((footerStatus === 'AUCTION_EXTENDED' && !hideFooterStatus) || (footerStatus !== 'AUCTION_EXTENDED'))) {
      <span
        [class]="'d-flex justify-content-md-start justify-content-center status-text ' + footerStatus">
        @if (determined)
        {
            {{ ('vehicle-footer.DETERMINE' | translate) }}
        } @else {
            {{ disableBidding ? (('vehicle-footer.AUCTION_EXPIRED') | translate) : (('vehicle-footer.' + footerStatus) | translate) }}
        }
      </span>
    }
    @if (['AUCTION_FINISHED','AUCTION_EXPIRED','BUYNOW_FINISHED'].includes(footerStatus)
      && ((['AUCTION','ENFORCED_AUCTION'].includes(offerType) && expirationDate) || (offerType === 'BUYNOW' && purchaseDate))) {
      <div class="status-text d-flex justify-content-md-start justify-content-center">
        <span class="pt-1 status-date">{{ (offerType === 'BUYNOW' ? purchaseDate : expirationDate) | date : 'dd.MM.yyyy - HH:mm:ss'}}</span>
        @if (!!expectedExpirationDate) {
          <button class="btn-popover" data-html="true"
            [floatUi]="expectedExpiration"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [placement]="NgxFloatUiPlacements.BOTTOM"
            [applyClass]="'float-ui-icon-content'">
            <ucs-icon [shape]="'info'" [size]="16" [class]="'blue'"></ucs-icon>
          </button>
        }
        <float-ui-content #expectedExpiration>
          <div class="float-ui-icon-content">{{'offer-status-buy-bid-expected-expiration' | translate }}
            <br/>{{ expectedExpirationDate| date : 'dd.MM.yyyy - HH:mm:ss'}}
          </div>
        </float-ui-content>
      </div>
    }
    <!--Angebot anzeigen (Listenansicht)-->
    @if (showOfferLink &&
      (!quickLinkToDirectSaleDealerIds || !currentDealerIsDirectSaleDealer || !userState.canBuyOffersPb)
      && !userState.isCustodianPb) {
      <div
        class="d-flex justify-content-start mb-2 offer-link">
        <a (click)="linkToOfferClicked.emit()" [routerLink]="'/maintenance/offer/' + offerId">
          <strong>{{ 'vehicle-footer.show-offer' | translate }}</strong></a>
        </div>
      }
      @if (showOfferLink && vehicle.offerDataDto.directSaleDealerIds && quickLinkToDirectSaleDealerIds && currentDealerIsDirectSaleDealer && userState.canBuyOffersPb) {
        <div class="offer-link d-flex justify-content-end">
          <a (click)="linkToOfferClicked.emit()" [routerLink]="'/offer/' + offerId"
            (click)="storeBacklink()">
            <strong>{{ 'vehicle-footer.show-directSaleOffer' | translate }}</strong></a>
          </div>
        }
        <!-- showing both the offer link and the bid-buy buttons would disturb the design -->
        @if (showBidBuyButtons && !showOfferLink) {
          <ucs-bid-buy [offerId]="offerId"
            [offerType]="offerType"
            data-cy="bid-buy-component"
            [inputPrice]="inputPrice"
            [disabled]="disableBidding"
            [thermalWindowAffected]="thermalWindowAffected"
            class="ps-0 w-100 d-md-block d-flex justify-content-center">
          </ucs-bid-buy>
        }
      }

    </div>
