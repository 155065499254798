/**
 * Dialog for buy now
 */
import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OfferService} from '../../service/offer.service';
import {TranslateService} from '@ngx-translate/core';
import {isVehicleItemBaseDto} from '../../misc/typeguard';
import {TaxService} from '../../service/tax.service';
import {State} from '../../store/user/user.reducers';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {first, tap} from 'rxjs/operators';
import {SystemSettingsService} from '../../service/system-settings.service';
import {combineLatest} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {ToastAlertSignalStore} from '../../store/alert/toast-alert.signal-store';

@Component({
  selector: 'ucs-buy-now-dialog',
  templateUrl: './buy-now-dialog.component.html',
  styleUrls: ['./buy-now-dialog.component.scss']
})
export class BuyNowDialogComponent implements OnInit, OnChanges {
  @Input() offer: BuyNowOfferDto;
  @Input() logOfferAccess = true;
  @Output() newOfferStatus = new EventEmitter<OfferStatus>();
  offerStatus: OfferStatus;
  vehicleItem: VehicleItemBaseDto;
  grossPrice: number;
  hideGrossPrice = false;
  userState: State;
  isNovaInfoGrossPriceEnabled: boolean;
  isShowNovaRefundEnabled: boolean;
  novaRefundTotalSum: number;
  showPPMV = false;
  extraTaxPrice: number;
  isThermalWindowAffectedEnabled: boolean;
  showThermalWindowAffected: boolean;

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private offerService: OfferService,
              private translate: TranslateService,
              private taxService: TaxService,
              private store: Store<fromRoot.AppState>,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.showPPMV = this.offer.country === 'HR' && (<VehicleItemBaseDto>this.offer.items[0]).extraTax.paid;
    if (this.showPPMV) {
      this.extraTaxPrice = (<VehicleItemBaseDto>this.offer.items[0]).extraTax.amount;
    }

    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('THERMAL_WINDOW_AFFECTED')
      .subscribe(value => {
        this.isThermalWindowAffectedEnabled = value;
        if (this.isThermalWindowAffectedEnabled) {
          this.systemSettingsService.getSystemFeatureSettingForChannel('THERMAL_WINDOW_AFFECTED.thermal_window_affected_step_one', this.offer.channel.data)
            .pipe(first())
            .subscribe(stringValue => {
              this.showThermalWindowAffected = JSON.parse(stringValue);
            });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const featureObservableNovaInfoGrossPrice = this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', this.offer.channel.data)
      .pipe(tap(isFeatureNovaInfoGrossPriceEnabled => this.isNovaInfoGrossPriceEnabled = isFeatureNovaInfoGrossPriceEnabled));
    const featureObservableShowNovaRefund = this.systemSettingsService
      .isSystemFeatureActivatedForChannel('SHOW_NOVA_REFUND', this.offer.channel.data)
      .pipe(tap(isShowNovaRefundEnabled => this.isShowNovaRefundEnabled = isShowNovaRefundEnabled));

    const userStoreObservable = this.store.select(fromRoot.getUserState).pipe(first());

    combineLatest([featureObservableNovaInfoGrossPrice, featureObservableShowNovaRefund,
      userStoreObservable])
      .subscribe(([isFeatureNovaInfoGrossPriceEnabled, isShowNovaRefundEnabled, userState]) => {
        this.isNovaInfoGrossPriceEnabled = isFeatureNovaInfoGrossPriceEnabled;
        this.isShowNovaRefundEnabled = isShowNovaRefundEnabled;
        if (!userState) { // wait for user state before we process because we need the country for price calculations
          return;
        }
        this.userState = userState;

        if (!isVehicleItemBaseDto(this.offer.items[0])) {
          console.error('Unknown OfferItem Type');
          return;
        }
        this.novaRefundTotalSum = null;
        for (const vehicleItem of (<VehicleItemBaseDto[]>this.offer.items)) {
          if (vehicleItem.extraTax.paid) {
            vehicleItem.extraTax.refund = vehicleItem.extraTax.refund === null ? 0 : vehicleItem.extraTax.refund;
            this.novaRefundTotalSum = this.novaRefundTotalSum + vehicleItem.extraTax.refund;
          }
        }
        this.vehicleItem = <VehicleItemBaseDto>this.offer.items[0];

        if (this.offer.nationalSale || this.isNovaInfoGrossPriceEnabled) {
          this.hideGrossPrice = false;
        }

        if (this.offer.price.gross) {
          this.grossPrice = this.offer.price.gross;
          return;
        }
        this.taxService.getGrossPriceWithExtraTax(
          this.offer.price.net, this.vehicleItem.vatType.data,
          this.vehicleItem.extraTax.paid ? 0 : this.vehicleItem.extraTax.rate,
          this.vehicleItem.extraTax.amount,
          <Country>this.userState.userInfo.country, this.offer.channel.data
        ).subscribe(grossValue => this.grossPrice = grossValue);

      });
  }

  /**
   * Buy this offer for the current user.
   */
  buynow() {
    this.offerService.purchaseOffer(this.offer.id, this.logOfferAccess)
      .subscribe({
        next: (newOfferStatus) => { // Success
          this.offerStatus = newOfferStatus;
          this.newOfferStatus.emit(this.offerStatus);

          if (this.offerStatus === 'FINISHED') {
            this.toastAlertStore.success(this.translate.instant('buy-now.dialog.purchase.successful'));
          }
        }, error: (err) => {
          if ((err as HttpErrorResponse).error.code === 'error.mail.recipients') {
            this.toastAlertStore.info(this.translate.instant('mail.error.no-recipients'));
            this.toastAlertStore.success(this.translate.instant('buy-now.dialog.purchase.successful'));
          }
        }
      });
  }
}

