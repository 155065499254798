<div class="row footer rounded-bottom">
  @if ((offer.offerType?.data ?? offer.offerType) !== 'ENFORCED_AUCTION') {
    <div class="col-auto col-lg-3 d-flex align-items-end">
      <ucs-net-price [currentPrice]="['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType?.data ?? offer.offerType) ? offer.highestBid : offer.price"
                     [startPrice]="startPrice"
                     [vatType]="vehicle?.vatType?.data"
      [novaPaid]="extraTax?.paid" [offerType]="offer.offerType?.data ?? offer.offerType" [channel]="channel"></ucs-net-price>
    </div>
  }
  <div class="col-auto col-lg-3 d-flex align-items-end">
    <ucs-gross-price [currentPrice]="['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType?.data ?? offer.offerType) ? offer.highestBid : offer.price"
                     [vatType]="vehicle?.vatType?.data"
                     [channel]="channel"
                     [offerType]="offer.offerType?.data ?? offer.offerType"
                     [extraTax]="extraTax"
                     [nationalSale]="!offer.nationalSale ? scopeSection === 'SALES_MAINTENANCE' : offer.nationalSale"
                     [scopeSection]="scopeSection"
                     [startPrice]="startPrice"
    [isEnforceAuction]="(offer.offerType?.data ?? offer.offerType) === 'ENFORCED_AUCTION'"></ucs-gross-price>
  </div>

    <!-- For offer scope mobile view tax info is hidden and displayed in the sales-single-offer-card instead -->
  <div class="col-auto col-lg-3 ms-lg-0 mt-2 mt-lg-0 align-items-center"
    [ngClass]="{'d-md-flex d-none': !!salesScope, 'd-flex': !salesScope}">
    <ucs-tax-nova-gross-profit
      [vatType]="vehicle?.vatType?.data"
      [grossProfitData]="offer.grossProfit"
      [channel]="channel" [country]="vehicle.country ?? offer.country" [isEnforceAuctionOffer]="(offer.offerType?.data ?? offer.offerType) === 'ENFORCED_AUCTION'"
      [nationalSale]="!offer.nationalSale ? scopeSection === 'SALES_MAINTENANCE' : offer.nationalSale"
      [currency]="['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType?.data ?? offer.offerType) ? offer.highestBid?.currency?.data : offer.price?.currency?.data"
      [extraTax]="extraTax" [vehicleOfferItem]="vehicle | cast: VehicleItemBaseDto" [scopeSection]="scopeSection">
    </ucs-tax-nova-gross-profit>
  </div>
  @if (['AUCTION','ENFORCED_AUCTION'].includes(offer.offerType?.data ?? offer.offerType) && offer?.eligible) {
    <div class="col-12 d-md-none d-flex my-2 my-md-0 justify-content-center"
      >
      <ucs-bid-status [status]="offer?.bidStatus?.data"
        [offerStatus]="offer.status?.data ?? offer.offerStatus"
        [maximumBid]="offer?.maximumBid"
        [myLastHighestBid]="offer?.myLastHighestBid"
        [myLastMaximumBid]="offer?.myLastMaximumBid"
        [tooLateBid]="offer?.tooLateBid"
        [purchasePrice]="offer?.purchase?.price"
        [highestBid]="offer?.highestBid"
        [purchaseReceived]="offer?.purchase?.purchaseReceived"
        [nationalSale]="offer?.nationalSale">
      </ucs-bid-status>
    </div>
  }
  @if ((offer.offerType?.data ?? offer.offerType) === 'BUYNOW'
        && offer.purchase?.buyingDealer
        && offer.purchase.buyingDealer.id === userState.userInfo.currentDealerId) {
    <div class="ms-auto my-auto col-12 buy pe-2 d-md-none d-flex justify-content-center">
      <ucs-buy-now-purchase-status [offerStatus]="offer.status?.data ?? offer.offerStatus">
      </ucs-buy-now-purchase-status>
    </div>
  }
  <div
    class="col-12 col-md-auto col-lg-3 mb-2 ms-auto d-flex align-items-end justify-content-center justify-content-lg-end"
    [ngClass]="{'bottom-empty': !showOfferLink && !['ACTIVE', 'EXTENDED'].includes(offer.status?.data ? offer.status.data : offer.offerStatus)}">
    <ucs-offer-status-buy-bid [expirationDate]="offer.expiration"
      [expectedExpirationDate]="offer.expectedExpiration"
      [purchaseDate]="offer?.purchase?.dateOfPurchase ?  offer.purchase.dateOfPurchase : offer.purchaseDate"
      [footerStatus]="footerStatus"
      [offerId]="offer.offerId ? offer.offerId : offer.id"
      [offerType]="offer.offerType?.data ? offer.offerType.data : offer.offerType"
      [showOfferLink]="showOfferLink"
      [showBidBuyButtons]="['ACTIVE', 'EXTENDED'].includes(offer.status?.data ? offer.status.data : offer.offerStatus)"
      [inputPrice]="footerStatus.startsWith('AUCTION') ? offer.minimumBid?.net : offer.price?.net"
      [channel]="channel"
      [offerStatus]="offer.status?.data ? offer.status.data : offer.offerStatus"
      [vehicle]="vehicle"
      [disableBidding]="disableBidding"
      [hideFooterStatus]="hideFooterStatus"
      [thermalWindowAffected]="thermalWindowAffected"
      [determined]="offer?.bidStatus?.data === 'DETERMINE'"
      (linkToOfferClicked)="linkToOfferClicked.emit()"
      class="w-100">
    </ucs-offer-status-buy-bid>
  </div>
</div>
