import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {SystemSettingsService} from '../../service/system-settings.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserSettingsService} from '../../service/user-settings.service';
import {UpdateUserSettingsAction} from '../../store/user/user-settings/user-settings.actions';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../service/authentication.service';
import {ToastAlertSignalStore} from '../../store/alert/toast-alert.signal-store';

@Component({
  selector: 'ucs-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  appScope = ['SALES_STORE', 'SALES_MAINTENANCE'] as AppScope[];
  selectedAppScope = 'SALES_STORE';

  allFeatures = [
    'NOTIFICATION', 'MAINTENANCE', 'INITIAL_PROPOSAL_ICON', 'LEASING_DEALER', 'LEASING_CONTRACT_NUMBER',
    'SHOW_EXTERNAL_ID', 'VEHICLE_MAINTENANCE', 'SHOW_NOVA', 'EXTERNAL_DEALER_MANUAL_ENTRY',
    'OFFER_PERIOD_EXTENSION', 'OPEN_TASK_LIST', 'SUBSTITUTION_RULE', 'MANDATORY_VEHICLE_DETAILS', 'MANDATORY_DOCUMENT',
    'VEHICLE_EDITABLE_IN_AUCTION', 'TRANSPORT', 'GROSS_PROFIT_DISPLAY', 'REVOKE_PURCHASE', 'SHOW_STAGE_INFO', 'SEND_OFFER_MAIL',
    'SHOW_NOVA_INFO_GROSS_PRICE_TO_FOREIGN_DEALERS', 'AUTOMATIC_OFFER_CREATION_BUYNOW', 'AUTOMATIC_OFFER_CREATION_AUCTION',
    'EXTERNAL_DOCUMENT_LINK', 'MINIMUM_PURCHASE_PRICE_CALCULATION', 'TAX_CALCULATION', 'SHOW_DROPDOWN_LOCAL_CHANGE',
    'ADD_CONVENIENCE_EMAIL_SUBJECT_TO_VEHICLE_DETAIL', 'EXTEND_BID_TABLE', 'SHOW_NOVA_REFUND', 'SURVEY_REQUESTS',
    'REQUEST_ETB', 'READ_ETB_REQUEST', 'CALCULATE_AND_PERSIST_BID_GROSS_PRICE', 'CAN_EDIT_LICENSE_PLATE', 'CAN_VIEW_LICENSE_PLATE',
    'CASCADE_DEALER_ACCESS_GROUPS', 'MIN_FUTURE_END_DATE_DAYS', 'AUCTION', 'FOLLOWUP_PROPOSAL', 'ARCHIVE_INVOICE_IN_DOCSTORE',
    'DIRECT_SALE_IDS_IN_SALES_MAINTENANCE', 'SHOW_VAT_VALUE'
  ] as SystemFeature[];

  enabledFeatures: DistributionChannelFeatures[];
  settings: DistributionChannelFeatureSettings[];
  enabledChannels: DistributionChannel[];
  imageGalleryConfig: ImageGalleryConfig;
  globalUserSettingDto: GlobalUserSettingDto;
  isImageGalleryEnabled: boolean;
  isSeller = false;
  isBuyer = false;
  showFeatures = false;
  showUserSettings = false;
  showImageGalleryConfig = false;
  userId: number;
  showSettingsMap = new Map([
    ['ALL_UC', false],
    ['DIN_BIL', false],
    ['PB', false],
    ['PIA', false],
    ['VGRX', false],
    ['ANY', false]
  ]);

  showFeaturesMap = new Map([
    ['ALL_UC', false],
    ['DIN_BIL', false],
    ['PB', false],
    ['PIA', false],
    ['VGRX', false],
    ['ANY', false]
  ]);
  private unsubscribe: Subject<void> = new Subject<void>();

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private systemSettingsService: SystemSettingsService,
              private userSettingsService: UserSettingsService,
              private store: Store<fromRoot.AppState>,
              private translateService: TranslateService) {
    this.translateService = translateService;
  }

  ngOnInit() {
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        this.enabledFeatures = userInfo?.enabledFeaturesPerChannelsAndDealers;
        this.settings = userInfo?.featureSettingsPerChannelsAndDealers;
        this.enabledChannels = userInfo?.enabledChannels;
        this.userId = userInfo?.id;
      });

    this.store.select(fromRoot.getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        this.isBuyer = AuthenticationService.canBuyOffers('PIA', userState)
          || AuthenticationService.canBuyOffers('ALL_UC', userState)
          || AuthenticationService.canBuyOffers('DIN_BIL', userState)
          || AuthenticationService.canBuyOffers('PB', userState)
          || AuthenticationService.canBuyOffers('VGRX', userState);
        this.isSeller = AuthenticationService.canSellOffersForAnyChannel(userState)
          || userState.isExaminerPia
          || userState.isCustodianPb
          || userState.isSurveyorPb
          || userState.isClerkPb
          || userState.isClerkBmiPb;
      });

    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('IMAGE_GALLERY_CONFIG')
      .subscribe(isFeatureEnabled => {
        this.isImageGalleryEnabled = isFeatureEnabled;
      });

    this.getInitialImageGalleryConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  hasFeatureEnabled(channel: DistributionChannel, companyNumber: string, feature: SystemFeature) {
    return this.enabledFeatures.filter(features => features.channel === channel)[0].companyFeatures
      .filter(companyFeatures => companyFeatures.companyNumber === companyNumber)[0]
      .features.includes(feature);
  }

  showSettingsChannel(channel: DistributionChannel) {
    this.showSettingsMap.set(channel, !this.showSettingsMap.get(channel));
  }

  showFeaturesChannel(channel: DistributionChannel) {
    this.showFeaturesMap.set(channel, !this.showFeaturesMap.get(channel));
  }

  enableSalesGalleryConfigGridView() {
    if (!this.imageGalleryConfig) {
      let config = this.createImageGallerySetting();
      config.salesShowGalleryView = true;
      this.imageGalleryConfig = config;
    } else {
      this.imageGalleryConfig.salesShowGalleryView = true;
    }
  }

  enableSalesGalleryConfigStandardView() {
    if (!this.imageGalleryConfig) {
      let config = this.createImageGallerySetting();
      config.salesShowGalleryView = false;
      this.imageGalleryConfig = config;
    } else {
      this.imageGalleryConfig.salesShowGalleryView = false;
    }
  }

  enableMaintenanceGalleryConfigGridView() {
    if (!this.imageGalleryConfig) {
      let config = this.createImageGallerySetting();
      config.maintenanceShowGalleryView = true;
      this.imageGalleryConfig = config;
    } else {
      this.imageGalleryConfig.maintenanceShowGalleryView = true;
    }
  }

  enableMaintenanceGalleryConfigStandardView() {
    if (!this.imageGalleryConfig) {
      let config = this.createImageGallerySetting();
      config.maintenanceShowGalleryView = false;
      this.imageGalleryConfig = config;
    } else {
      this.imageGalleryConfig.maintenanceShowGalleryView = false;
    }
  }

  setGalleryColumnsAndRowsSales(imageGalleryConfigNr: number) {
    if (!this.imageGalleryConfig) {
      let config = this.createImageGallerySetting();
      config.imageGalleryConfig.SALES_STORE[imageGalleryConfigNr].enabled = true;
      config.imageGalleryConfig.SALES_STORE.forEach((value, index) => {
        if (index !== imageGalleryConfigNr) {
          value.enabled = false;
        }
      });
      this.imageGalleryConfig = config;
    } else {
      this.imageGalleryConfig.imageGalleryConfig.SALES_STORE[imageGalleryConfigNr].enabled = true;
      this.imageGalleryConfig.imageGalleryConfig.SALES_STORE.forEach((value, index) => {
        if (index !== imageGalleryConfigNr) {
          value.enabled = false;
        }
      });
    }
  }

  setGalleryColumnsAndRowsMaintenance(imageGalleryConfigNr: number) {
    if (!this.imageGalleryConfig) {
      let config = this.createImageGallerySetting();
      config.imageGalleryConfig.SALES_STORE[imageGalleryConfigNr].enabled = true;
      config.imageGalleryConfig.SALES_STORE.forEach((value, index) => {
        if (index !== imageGalleryConfigNr) {
          value.enabled = false;
        }
      });
      this.imageGalleryConfig = config;
    } else {
      this.imageGalleryConfig.imageGalleryConfig.SALES_MAINTENANCE[imageGalleryConfigNr].enabled = true;
      this.imageGalleryConfig.imageGalleryConfig.SALES_MAINTENANCE.forEach((value, index) => {
        if (index !== imageGalleryConfigNr) {
          value.enabled = false;
        }
      });
    }
  }

  saveImageGalleryConfig() {
    this.globalUserSettingDto.value = JSON.stringify(this.imageGalleryConfig);
    this.userSettingsService.saveUserSetting(this.globalUserSettingDto, 'IMAGE_GALLERY_CONFIG')
      .subscribe(response => {
        this.store.dispatch(new UpdateUserSettingsAction(this.userId));
        this.toastAlertStore.success(this.translateService
          .instant('user-settings.image-gallery-config.save-success'));
      },
      () => {
        this.toastAlertStore.danger(this.translateService
          .instant('user-settings.image-gallery-config.save-error'));
      });
  }

  getInitialImageGalleryConfig() {
    this.store.select(fromRoot.getUserSettings)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(settings => {
        this.globalUserSettingDto = settings?.find(setting => setting.propertyKey === 'IMAGE_GALLERY_CONFIG');
        if (this.globalUserSettingDto) {
          this.imageGalleryConfig = JSON.parse(this.globalUserSettingDto.value);
        }
      });
  }

  createImageGallerySetting(): ImageGalleryConfig {
    this.globalUserSettingDto = {
      propertyKey: 'USER_GALLERY_OPTIONS',
      descriptionKey: 'global-user-settings.description-key.user-gallery-options',
      valueDescriptionKey: 'global-user-settings.value-description-key.user-gallery-options',
      value: undefined,
      type: 'VALUE_TYPE_OBJECT'
    };
    return {
      imageGalleryConfig: {
        SALES_STORE: [
          {columns: 4, rows: 6, enabled: false},
          {columns: 6, rows: 8, enabled: false},
          {columns: 8, rows: 10, enabled: true}], //default selection
        SALES_MAINTENANCE: [
          {columns: 4, rows: 6, enabled: false},
          {columns: 6, rows: 8, enabled: false},
          {columns: 8, rows: 10, enabled: true}] //default selection
      },
      salesShowGalleryView: undefined,
      maintenanceShowGalleryView: undefined
    };
  }
}
