<div class="detail-container p-3 mt-3">
  <div class="d-flex">
    <ucs-icon [shape]="'warning'" [class]="'grey'" [size]="70"></ucs-icon>
    <div class="ms-3">
      <h1>{{ 'auth-failure.title' | translate }} </h1>
      <p class="mb-1">{{ 'auth-failure.info-text' | translate }}</p>
      <p>
        <ucs-icon [shape]="'info'" [class]="'blue'" [size]="24"></ucs-icon>
        <span>{{ 'auth-failure.info-text.contact-person' | translate }}</span>
      </p>
    </div>
  </div>
</div>
