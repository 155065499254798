import {
  AfterViewInit,
  Component,
  ElementRef, inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {OfferService} from '../../../service/offer.service';
import {State} from '../../../store/user/user.reducers';
import {SystemSettingsService} from '../../../service/system-settings.service';
import {TranslateService} from '@ngx-translate/core';
import {PushBacklinkAction} from '../../../store/detail-page/detail-page.actions';
import {ucsIsNil} from '../../../misc/utils';
import {CarouselLibConfig, DescriptionStrategy} from '@ks89/angular-modal-gallery';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NgxFloatUiPlacements} from 'ngx-float-ui';
import {ToastAlertSignalStore} from '../../../store/alert/toast-alert.signal-store';

/**
 * An offer card for UCS sales list views, providing information based on its display mode
 */
@Component({
  selector: 'ucs-sales-single-offer-card',
  templateUrl: './sales-single-offer-card.component.html',
  styleUrls: ['./sales-single-offer-card.component.scss']
})
export class SalesSingleOfferCardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() offer: BuyNowOfferDto & AuctionOfferDto & EnforcedAuctionOfferDto;
  @Input() enableNote = true;

  @ViewChild('favNoteElement') favnote: ElementRef;

  viewSettings: ViewTag[];
  vehicle: VehicleItemBaseDto;
  galleryImages: MediaInfoDto[] = [];
  userState: State;
  countryFlag: string;
  footerStatus: VehicleFooterStatus;
  showExternalDocumentLink: boolean;
  showVatType: boolean;
  isExpired = false;
  imageUrls = [];
  carouselConfig: CarouselLibConfig;
  showFurtherData: boolean = false;
  mobileView: boolean;

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(public store: Store<fromRoot.AppState>, private offerService: OfferService,
              private systemSettingService: SystemSettingsService,
              private translate: TranslateService,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit() {
    this.initProperties();
    this.initReduxStates();
    this.initGallery();
    this.initFeatureFlags();
    this.observeBreakpoints();
  }

  private initProperties() {
    this.footerStatus = this.offerService.deriveVehicleFooterStatusFromOffer(this.offer);
    this.vehicle = this.offer.items[0] as VehicleItemBaseDto;
    this.initCountryFlag();
  }

  private initReduxStates() {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });

    this.store.select(fromRoot.getViewSettings)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(viewSettings => {
        if (viewSettings) {
          this.viewSettings = viewSettings;
        } else {
          this.viewSettings = <ViewTag[]>['MILEAGE', 'INITIAL-REGISTRATION', 'FUEL', 'POWER'];
        }
      });
  }

  private initFeatureFlags() {
    this.systemSettingService
      .getSystemFeatureSettingForChannel('EXTERNAL_DOCUMENT_LINK.external_document_link', 'ALL_UC')
      .pipe(first())
      .subscribe(value => {
        if (value === 'true') {
          this.showExternalDocumentLink = true;
        }
      });

    this.systemSettingService
      .isSystemFeatureActivatedForChannel('SHOW_VAT_VALUE', this.offer.channel.data)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        this.showVatType = !value;
      });

    this.setupCarouselGallery();
  }

  private initCountryFlag() {
    // custodyDealer country default
    this.countryFlag = (<VehicleItemBaseDto>this.offer.items[0]).custodyDealer?.country.data.toLowerCase();

    // fallback country seller (default at PIA and ALL_UC)
    if (!this.countryFlag) {
      this.countryFlag = this.offer.country.toLowerCase();
    }
  }

  private initGallery() {
    // add images to gallery
    this.addImagesToGallery();

    // add images to slider
    for (let image of this.vehicle.images) {
      if (image?.url) {
        this.imageUrls.push(image.url);
      }
    }
  }

  private addImagesToGallery() {
    this.galleryImages = [];
    this.vehicle.images.forEach((image, index) => {
      if (image?.url) {
        this.galleryImages.push(image);
      }
    });
  }

  /**
   * We need the ngOnChanges method to ensure vehicleDetails is updated when offerItem is updated in parent
   * Otherwise Angular does not propagate changes, only directly to the input variable (offer)
   * @param {SimpleChanges} changes: The changes that occurred
   */
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'offer') {
        this.initProperties();
      }
    }
  }

  ngAfterViewInit() {
    if (this.supportsLocalStorage() && this.enableNote) {
      const items = localStorage.getItem('ucs-favnote-' + this.offer.id);
      if (items !== null) {
        this.favnote.nativeElement.textContent = items;
        this.showNoteInput();
      }
    }
  }


  private observeBreakpoints() {
    this.breakpointObserver.observe([
      '(max-width: 767px)',
      '(min-width: 768px)',
    ]).pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        this.mobileView = this.breakpointObserver.isMatched('(max-width: 767px)');
      });
  }

  /**
   * Check if the current device supports local storage.
   * @returns {boolean} If the current device supports local storage.
   */
  private supportsLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  /**
   * Stores the backlink target for the offer detail page in the NgRx store
   */
  storeBacklink() {
    this.store.dispatch(new PushBacklinkAction('/offer#offer' + this.offer.id));
  }

  /**
   * Use to determine the view state of a field configured by the view settings
   *
   * @param viewSettingTag view tag
   */
  view(viewSettingTag: ViewTag): boolean {
    return this.viewSettings.includes(viewSettingTag);
  }

  showNoteInput() {
    this.favnote.nativeElement.className = 'favnote col col-md-6';
  }

  /**
   * Save a note to a bookmarked offer, if it is non-empty
   */
  saveNote() {
    if (!this.enableNote) {
      return;
    }
    if (this.favnote.nativeElement.textContent === '') {
      this.favnote.nativeElement.className = 'favnote d-none';
      try {
        localStorage.removeItem('ucs-favnote-' + this.offer.id);
      } catch (e) {
        this.toastAlertStore.warning(this.translate.instant('error.localstorage'));
      }
    } else {
      try {
        localStorage.setItem('ucs-favnote-' + this.offer.id, this.favnote.nativeElement.textContent);
      } catch (e) {
        this.toastAlertStore.warning(this.translate.instant('error.localstorage'));
      }
    }
  }

  private setupCarouselGallery() {
    this.carouselConfig = {
      carouselImageConfig: {
        description: {
          strategy: DescriptionStrategy.ALWAYS_HIDDEN
        }
      },
      carouselDotsConfig: {
        visible: false
      },
      carouselPreviewsConfig: {
        visible: false
      },
      carouselPlayConfig: {
        autoPlay: false,
        interval: 3000,
        pauseOnHover: true
      }
    };
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly ucsIsNil = ucsIsNil;
  protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}

