import {CommonModule, DecimalPipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {
  OfferLabelValueComponent
} from '../sales/offer/offer-detail/offer-single-detail/offer-label-value/offer-label-value.component';
import {AuthComponent} from './component/auth/auth.component';
import {BasketDialogComponent} from './component/basket-dialog/basket-dialog.component';
import {BidBuyComponent} from './component/bid-buy/bid-buy.component';
import {BidDialogComponent} from './component/bid-dialog/bid-dialog.component';
import {BidStatusComponent} from './component/bid-status/bid-status.component';
import {BuyNowDialogComponent} from './component/buy-now-dialog/buy-now-dialog.component';
import {CountdownComponent} from './component/countdown/countdown.component';
import {DealerAutocompleteComponent} from './component/dealer-autocomplete/dealer-autocomplete.component';
import {ErrorComponent} from './component/error/error.component';
import {FilterBulletListComponent} from './component/filter-bullets/filter-bullet-list/filter-bullet-list.component';
import {FilterBulletComponent} from './component/filter-bullets/filter-bullet/filter-bullet.component';
import {ContactComponent} from './component/footer/contact/contact.component';
import {FooterComponent} from './component/footer/footer.component';
import {LegalNoticeComponent} from './component/footer/legal-notice/legal-notice.component';
import {TermsComponent} from './component/footer/terms/terms.component';
import {ForbiddenComponent} from './component/forbidden/forbidden.component';
import {IconComponent} from './component/icon/icon.component';
import {LogoutComponent} from './component/logout/logout.component';
import {OfferListCoreComponent} from './component/offers/offer-list-core/offer-list-core.component';
import {OfferSellerComponent} from './component/offers/offer-seller/offer-seller.component';
import {OfferSmallComponent} from './component/offers/offer-small/offer-small.component';
import {BundleCollageComponent} from './component/offers/package-collage/bundle-collage.component';
import {VehicleSellerComponent} from './component/offers/vehicle-seller/vehicle-seller.component';
import {PriceDetailComponent} from './component/price-detail/price-detail.component';
import {ProposalDialogComponent} from './component/proposal-dialog/proposal-dialog.component';
import {SaveDialogComponent} from './component/save-dialog/save-dialog.component';
import {SearchFilterFinishedComponent} from './component/search-filter-finished/search-filter-finished.component';
import {
  VehicleSearchFilterSidebarComponent
} from './component/vehicle-search-filter-sidebar/vehicle-search-filter-sidebar.component';
import {SearchDropdownComponent} from './component/search-filter/search-dropdown/search-dropdown.component';
import {SearchEquipmentComponent} from './component/search-filter/search-equipment/search-equipment.component';
import {SearchFilterComponent} from './component/search-filter/search-filter.component';
import {SearchLocationComponent} from './component/search-filter/search-location/search-location.component';
import {
  SearchOfferVariantComponent
} from './component/search-filter/search-offer-variant/search-offer-variant.component';
import {
  SearchRangeDropdownComponent
} from './component/search-filter/search-range-dropdown/search-range-dropdown.component';
import {SearchSimpleInputComponent} from './component/search-filter/search-simple-input/search-simple-input.component';
import {
  SearchToggleButtonComponent
} from './component/search-filter/search-toggle-button/search-toggle-button.component';
import {SurveyRequestComponent} from './component/survey-request/survey-request.component';
import {ToggleSwitchComponent} from './component/toggle-switch/toggle-switch.component';
import {TopMenuComponent} from './component/top-menu/top-menu.component';
import {TransportComponent} from './component/transport/transport.component';
import {VehicleListComponent} from './component/vehicles/vehicle-list/vehicle-list.component';
import {VehicleComponent} from './component/vehicles/vehicle/vehicle.component';
import {UcsMissingTranslationHandler} from './misc/translate/UcsMissingTranslationHandler';
import {CcmPipe} from './pipe/ccm.pipe';
import {GramsPerHundredKmPipe} from './pipe/grams-per-hundred-km.pipe';
import {InputFilterPipe} from './pipe/input-filter.pipe';
import {KgPipe} from './pipe/kg.pipe';
import {KmPipe} from './pipe/km.pipe';
import {KwPipe} from './pipe/kw.pipe';
import {LitersPerHundredKmPipe} from './pipe/liters-per-hundred-km.pipe';
import {PsPipe} from './pipe/ps.pipe';
import {TimeRemainingPipe} from './pipe/time-remaining.pipe';
import {PricePipe} from './pipe/price.pipe';
import {AuthenticationService} from './service/authentication.service';
import {OfferService} from './service/offer.service';
import {SearchService} from './service/search.service';
import {UserService} from './service/user.service';
import {DealerService} from './service/dealer.service';
import {VehicleMaintenanceService} from './service/vehicle-maintenance.service';
import {LogComponent} from './component/log/log.component';
import {DealerLockedMessageComponent} from './component/dealer-locked-message/dealer-locked-message.component';
import {GtcMessageComponent} from './component/gtc-message/gtc-message.component';
import {SpinnerComponent} from './component/spinner/spinner.component';
import {SpinnerService} from './service/spinner.service';
import {AuthFailureComponent} from './component/auth/auth-failure.component';
import {ViewSettingsComponent} from './component/view-settings/view-settings.component';
import {TimerService} from './service/timer.service';
import {DocumentMissingMessageComponent} from './component/document-missing-message/document-missing-message.component';
import {NetGrossInputComponent} from './component/net-gross-input/net-gross-input.component';
import {
  ExternalDealerBidDialogComponent
} from './component/external-dealer-bid-dialog/external-dealer-bid-dialog.component';
import {ManualDealerInputComponent} from './component/manual-dealer-input/manual-dealer-input.component';
import {TaxService} from './service/tax.service';
import {UserSettingsComponent} from './component/user-settings/user-settings.component';
import {SystemSettingsService} from './service/system-settings.service';
import {VehicleStatusComponent} from './component/vehicles/vehicle-status/vehicle-status.component';
import {VehicleFooterComponent} from './component/vehicles/vehicle/vehicle-footer/vehicle-footer.component';
import {PriceDisplayComponent} from './component/price-display/price-display.component';
import {PriceInputComponent} from './component/input/price-input/price-input.component';
import {EmissionClassPipe} from './pipe/emission-class.pipe';
import {
  VehicleIdentificationFullTextSearchComponent
} from './component/vehicle-identification-full-text-search/vehicle-identification-full-text-search.component';
import {VehicleMaintenanceSearchService} from './service/vehicle-maintenance-search.service';
import {
  MaintenanceSearchFilterComponent
} from './component/maintenance-search-filters/maintenance-search-filter.component';
import {OpenTasksComponent} from './component/open-tasks/open-tasks.component';
import {SubstitutionsListComponent} from './component/substitutions-list/substitutions-list.component';
import {DataCyDirective} from './misc/data-cy/data-cy.directive';
import {GrossProfitComponent} from './component/card-footer/tax-nova-gross-profit/gross-profit/gross-profit.component';
import {CalcIconComponent} from './component/calc-icon/calc-icon.component';
import {CardFooterComponent} from './component/card-footer/card-footer.component';
import {NetPriceComponent} from './component/card-footer/net-price/net-price.component';
import {StartingOrOfferPriceComponent} from './component/card-footer/starting-price/starting-or-offer-price.component';
import {MarketValueComponent} from './component/card-footer/market-value/market-value.component';
import {
  EAuctionInformationComponent
} from './component/card-footer/enforced-auction/e-auction-information/e-auction-information.component';
import {GrossPriceComponent} from './component/card-footer/gross-price/gross-price.component';
import {
  TaxNovaGrossProfitComponent
} from './component/card-footer/tax-nova-gross-profit/tax-nova-gross-profit.component';
import {OfferStatusBuyBidComponent} from './component/card-footer/offer-status-buy-bid/offer-status-buy-bid.component';
import {LabelPriceComponent} from './component/price-detail/label-price/label-price.component';
import {HideableDataDirective} from './visibility/hideable-data.directive';
import {JobMonitoringService} from './service/job-monitoring.service';
import {PaginationComponent} from './component/pagination/pagination.component';
import {BrowserAlertDialogComponent} from './component/browser-alert-dialog/browser-alert-dialog.component';
import {NgbModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {EmailSubjectComponent} from './component/email-subject/email-subject.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ChannelSelectionComponent} from './component/channel-selection/channel-selection.component';
import {OfferChannelSelectionComponent} from './component/channel-selection/offer/offer-channel-selection.component';
import {SurveyMaintenanceService} from './service/survey-maintenance.service';
import {
  CascadeDealerAccessGroupsComponent
} from './component/cascade-dealer-access-groups/cascade-dealer-access-groups.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SearchMultiSelectComponent} from './component/search-filter/search-multi-select/search-multi-select.component';
import {
  SurveyRequestDetailComponent
} from './component/survey-request/survey-request-detail/survey-request-detail.component';
import {UserSettingsService} from './service/user-settings.service';
import {ArchiveSearchService} from './service/archive-search.service';
import {PrintDialogComponent} from './component/print-dialog/print-dialog.component';
import {VehicleWsUpdateService} from './service/vehicle-ws-update.service';
import {BidWsUpdateOfferBasedService} from './service/bid-ws-update-offer-based.service';
import {ReportingWsUpdateService} from './service/reporting-ws-update.service';
import {DatepickerRangeComponent} from './component/range-datepicker/datepicker-range/datepicker-range.component';
import {LogoIconComponent} from './component/logo-icon/logo-icon.component';
import {TruncatePipe} from './pipe/truncate.pipe';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {
  NotificationComponent
} from '../administration/system-settings/user-notifications/notification/notification.component';
import {OfferExpirationLockTimeService} from './service/offer-expiration-lock-time.service';
import {NgxFloatUiModule} from 'ngx-float-ui';
import {BackNavigationComponent} from './component/back-navigation/back-navigation.component';
import {NoMatchComponent} from './component/no-match/no-match.component';
import {SortingSelectionComponent} from './component/sorting-selection/sorting-selection.component';
import {HighlightEquipmentComponent} from './component/highlight-equipment/highlight-equipment.component';
import {
  SearchToggleAuctionBuynowComponent
} from './component/search-filter/search-toggle-auction-buynow/search-toggle-auction-buynow.component';
import {
  MissingDocumentsFooterComponent
} from './component/card-footer/missing-documents-footer/missing-documents-footer.component';
import {
  EnforcedAuctionFooterComponent
} from './component/card-footer/enforced-auction/enforced-auction-footer.component';
import {DefaultFooterComponent} from './component/card-footer/default-footer/default-footer.component';
import {
  SalesSingleOfferCardComponent
} from './component/offers/sales-single-offer-card/sales-single-offer-card.component';
import {
  SalesBundleOfferCardComponent
} from './component/offers/sales-bundle-offer-card/sales-bundle-offer-card.component';
import {OfferBookmarkingComponent} from './component/offers/offer-bookmarking/offer-bookmarking.component';
import {InfoIconComponent} from './component/info-icon.component/info-icon.component';
import {BuyNowPurchaseStatusComponent} from './component/buy-now-status/buy-now-purchase-status.component';
import {NumberFormatDirective} from './directive/number-format.directive';
import {APP_CONFIG} from './misc/inject-tokens';
import {AppConfig} from './model/app-config.model';
import {KwhPerHundredKmPipe} from './pipe/kwh-per-hundred-km.pipe';
import {ExpiringOfferCalendarComponent} from './component/calendar/expiring-offer-calendar/expiring-offer-calendar.component';
import {ThermalWindowDialogComponent} from './component/thermal-window-dialog/thermal-window-dialog.component';
import {ApprovalService} from './service/approval.service';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {CastPipe} from './pipe/cast.pipe';
import {DetailGalleryComponent} from '../standalone/detail-gallery/detail-gallery.component';
import {PageSizeComponent} from './component/pagination/page-size/page-size.component';

let salesApiUrl: string;

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, salesApiUrl + '/translations?lang=', '');
}

export function createMissingTranslationLoader() {
  return new UcsMissingTranslationHandler();
}

export const GTC_FILENAMES_MAP = new Map<DistributionChannel, Map<Country, string[]>>([
  ['PIA', new Map<Country, string[]>([
    ['AT', ['GTC_PIA_AT_de_v4.pdf']],
    ['SI', ['GTC_PIA_SI_sl_v1.pdf', 'GTC_PIA_SI_en_v1.pdf']],
    ['HR', ['GTC_PIA_HR_hr_v1.pdf', 'GTC_PIA_HR_en_v1.pdf']],
    ['HU', ['GTC_PIA_HU_hu_v1.pdf', 'GTC_PIA_HU_en_v1.pdf']],
    ['RO', ['GTC_PIA_RO_ro_v1.pdf', 'GTC_PIA_RO_en_v1.pdf']],
    ['IT', ['GTC_PIA_IT_en_v1.pdf', 'PTU_PIA_IT_en_v1.pdf']],
  ])],
  ['PB', new Map<Country, string[]>([
    ['AT', ['GTC_PB_AT_de_2024_10.pdf','GTC_PB_AT_en_2024_10.pdf']],
    ['BG', ['GTC_PB_BG_bg_2010_04.pdf']],
    ['BA', ['GTC_PB_BA_bs_2022_02.pdf']],
    ['DE', ['GTC_PB_AT_de_2020_10.pdf']],
    ['DE', ['GTC_Placeholder_en.pdf']],
    ['HU', ['GTC_PB_HU_hu_2020_10.pdf','GTC_PB_HU_en_2020_10.pdf']],
    ['RO', ['GTC_PB_RO_ro_2020_10.pdf']],
    ['RS', ['GTC_PB_RS_sr_2022_04.pdf']],
    ['SI', ['GTC_PB_SI_sl_2020_07.pdf']],
    ['MK', ['GTC_PB_MK_mk_2022_08.pdf']],
    ['HR', ['GTC_PB_HR_hr_2023_01.pdf']],
    ['UA', ['GTC_PB_UA_uk_2020_10.pdf']]
  ])],
  ['ALL_UC', new Map<Country, string[]>([
    ['AT', ['GTC_ALL_UC_AT_de_v2.pdf']],
    ['HU', ['GTC_ALL_UC_HU_hu_v2.pdf']],
    ['RO', ['GTC_ALL_UC_RO_ro_2023_05.pdf']]
  ])]
]);

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    TypeaheadModule,
    ButtonsModule,
    RouterModule,
    InlineSVGModule,
    ProgressbarModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, APP_CONFIG]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: createMissingTranslationLoader
      }
    }),
    NgbToastModule,
    ClipboardModule,
    NgbModule,
    NgSelectModule,
    NgxFloatUiModule,
    GalleryModule,
    DetailGalleryComponent,
    PageSizeComponent
  ],
  declarations: [
    TopMenuComponent,
    DocumentMissingMessageComponent,
    SearchFilterComponent,
    SearchRangeDropdownComponent,
    SearchDropdownComponent,
    SearchMultiSelectComponent,
    SearchOfferVariantComponent,
    SearchLocationComponent,
    SearchEquipmentComponent,
    OfferListCoreComponent,
    OfferSmallComponent,
    FooterComponent,
    IconComponent,
    FilterBulletListComponent,
    FilterBulletComponent,
    InputFilterPipe,
    TimeRemainingPipe,
    KgPipe,
    KmPipe,
    KwPipe,
    PsPipe,
    CcmPipe,
    LitersPerHundredKmPipe,
    GramsPerHundredKmPipe,
    PricePipe,
    EmissionClassPipe,
    SearchToggleButtonComponent,
    SearchSimpleInputComponent,
    BundleCollageComponent,
    SaveDialogComponent,
    ToggleSwitchComponent,
    ErrorComponent,
    CountdownComponent,
    BidBuyComponent,
    BuyNowDialogComponent,
    BidDialogComponent,
    BidStatusComponent,
    SearchFilterFinishedComponent,
    OfferSellerComponent,
    LogoutComponent,
    ForbiddenComponent,
    AuthComponent,
    AuthFailureComponent,
    TermsComponent,
    LegalNoticeComponent,
    ContactComponent,
    VehicleSellerComponent,
    PriceDetailComponent,
    OfferLabelValueComponent,
    VehicleComponent,
    VehicleListComponent,
    DealerAutocompleteComponent,
    VehicleSearchFilterSidebarComponent,
    BasketDialogComponent,
    ProposalDialogComponent,
    TransportComponent,
    SurveyRequestComponent,
    SurveyRequestDetailComponent,
    LogComponent,
    DealerLockedMessageComponent,
    GtcMessageComponent,
    SpinnerComponent,
    ViewSettingsComponent,
    NetGrossInputComponent,
    ExternalDealerBidDialogComponent,
    ManualDealerInputComponent,
    UserSettingsComponent,
    VehicleStatusComponent,
    VehicleFooterComponent,
    PriceDisplayComponent,
    PriceInputComponent,
    VehicleFooterComponent,
    VehicleIdentificationFullTextSearchComponent,
    MaintenanceSearchFilterComponent,
    OpenTasksComponent,
    NotificationComponent,
    SubstitutionsListComponent,
    DataCyDirective,
    GrossProfitComponent,
    CalcIconComponent,
    CardFooterComponent,
    NetPriceComponent,
    StartingOrOfferPriceComponent,
    MarketValueComponent,
    EAuctionInformationComponent,
    GrossPriceComponent,
    TaxNovaGrossProfitComponent,
    OfferStatusBuyBidComponent,
    LabelPriceComponent,
    HideableDataDirective,
    PaginationComponent,
    BrowserAlertDialogComponent,
    EmailSubjectComponent,
    ChannelSelectionComponent,
    OfferChannelSelectionComponent,
    CascadeDealerAccessGroupsComponent,
    PrintDialogComponent,
    DatepickerRangeComponent,
    LogoIconComponent,
    TruncatePipe,
    BackNavigationComponent,
    SortingSelectionComponent,
    NoMatchComponent,
    HighlightEquipmentComponent,
    SearchToggleAuctionBuynowComponent,
    MissingDocumentsFooterComponent,
    EnforcedAuctionFooterComponent,
    DefaultFooterComponent,
    SalesSingleOfferCardComponent,
    SalesBundleOfferCardComponent,
    OfferBookmarkingComponent,
    InfoIconComponent,
    BuyNowPurchaseStatusComponent,
    NumberFormatDirective,
    KwhPerHundredKmPipe,
    ExpiringOfferCalendarComponent,
    ThermalWindowDialogComponent,
    CastPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    TopMenuComponent,
    SearchFilterComponent,
    SearchRangeDropdownComponent,
    SearchDropdownComponent,
    SearchOfferVariantComponent,
    SearchLocationComponent,
    SearchEquipmentComponent,
    TranslateModule,
    OfferListCoreComponent,
    FooterComponent,
    IconComponent,
    FilterBulletListComponent,
    FilterBulletComponent,
    InputFilterPipe,
    TimeRemainingPipe,
    KgPipe,
    KmPipe,
    KwPipe,
    PsPipe,
    CcmPipe,
    LitersPerHundredKmPipe,
    GramsPerHundredKmPipe,
    PricePipe,
    EmissionClassPipe,
    SearchToggleButtonComponent,
    SearchSimpleInputComponent,
    BundleCollageComponent,
    SaveDialogComponent,
    ToggleSwitchComponent,
    ErrorComponent,
    CountdownComponent,
    BidBuyComponent,
    BuyNowDialogComponent,
    BidDialogComponent,
    BidStatusComponent,
    SearchFilterFinishedComponent,
    OfferSellerComponent,
    LogoutComponent,
    ForbiddenComponent,
    AuthComponent,
    AuthFailureComponent,
    VehicleListComponent,
    VehicleSellerComponent,
    PriceDetailComponent,
    OfferLabelValueComponent,
    DealerAutocompleteComponent,
    VehicleSearchFilterSidebarComponent,
    BasketDialogComponent,
    ProposalDialogComponent,
    TransportComponent,
    SurveyRequestComponent,
    SurveyRequestDetailComponent,
    LogComponent,
    DealerLockedMessageComponent,
    GtcMessageComponent,
    SpinnerComponent,
    DocumentMissingMessageComponent,
    SpinnerComponent,
    ViewSettingsComponent,
    NetGrossInputComponent,
    ExternalDealerBidDialogComponent,
    ManualDealerInputComponent,
    UserSettingsComponent,
    VehicleStatusComponent,
    VehicleFooterComponent,
    MaintenanceSearchFilterComponent,
    PriceInputComponent,
    OpenTasksComponent,
    NotificationComponent,
    SubstitutionsListComponent,
    GrossProfitComponent,
    LabelPriceComponent,
    CalcIconComponent,
    CardFooterComponent,
    HideableDataDirective,
    PaginationComponent,
    BrowserAlertDialogComponent,
    EmailSubjectComponent,
    ChannelSelectionComponent,
    OfferChannelSelectionComponent,
    CascadeDealerAccessGroupsComponent,
    SearchMultiSelectComponent,
    PrintDialogComponent,
    DatepickerRangeComponent,
    LogoIconComponent,
    TruncatePipe,
    BackNavigationComponent,
    NoMatchComponent,
    SortingSelectionComponent,
    HighlightEquipmentComponent,
    SearchToggleAuctionBuynowComponent,
    MissingDocumentsFooterComponent,
    EnforcedAuctionFooterComponent,
    DefaultFooterComponent,
    SalesSingleOfferCardComponent,
    SalesBundleOfferCardComponent,
    OfferBookmarkingComponent,
    InfoIconComponent,
    BuyNowPurchaseStatusComponent,
    KwhPerHundredKmPipe,
    ExpiringOfferCalendarComponent,
    ThermalWindowDialogComponent,
    GalleryModule,
    NgxFloatUiModule,
    CastPipe,
    DetailGalleryComponent,
    PageSizeComponent,
  ],
  providers: [
    UcsMissingTranslationHandler,
    SearchService,
    OfferService,
    TaxService,
    UserService,
    DealerService,
    TimerService,
    AuthenticationService,
    VehicleMaintenanceService,
    SurveyMaintenanceService,
    SpinnerService,
    PricePipe,
    SystemSettingsService,
    VehicleMaintenanceSearchService,
    JobMonitoringService,
    DecimalPipe,
    UserSettingsService,
    ArchiveSearchService,
    VehicleWsUpdateService,
    BidWsUpdateOfferBasedService,
    ReportingWsUpdateService,
    OfferExpirationLockTimeService,
    ApprovalService
  ]
})
export class SharedModule {
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
    salesApiUrl = config.salesApiUrl;
  }
}
