import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {VehicleMaintenanceService} from '../../service/vehicle-maintenance.service';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/app.reducers';
import {Subject} from 'rxjs';
import {UpdateOpenTasksAction} from '../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {SpinnerService} from '../../service/spinner.service';
import {ToastAlertSignalStore} from '../../store/alert/toast-alert.signal-store';

@Component({
  selector: 'ucs-open-tasks',
  templateUrl: './open-tasks.component.html',
  styleUrls: ['./open-tasks.component.scss']
})
export class OpenTasksComponent implements OnInit, OnDestroy {
  @Input() isSpinnerActive: boolean;
  openTasks: OpenTaskListDto[];
  selectedTasks: number[];
  filterCategories = ['SURVEYOR', 'CUSTODY_DEALER', 'FOLLOWUP_PROPOSAL', 'BUY_NOW'];
  selectedCategories = ['SURVEYOR', 'CUSTODY_DEALER', 'FOLLOWUP_PROPOSAL', 'BUY_NOW'];
  urgeButtonDisabled = false;
  private unsubscribe: Subject<void> = new Subject<void>();

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private vehicleMaintenanceService: VehicleMaintenanceService,
              private translateService: TranslateService, private store: Store<fromRoot.AppState>, private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.store.select(fromRoot.getOpenTasks)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(openTasks => {
        this.openTasks = openTasks.filter(t => !!t.tasks);
        this.selectedTasks = [];
        if (openTasks && this.isSpinnerActive) {
          this.spinnerService.deactivateSpinner();
        }
      });
  }

  /**
   * Toggles an open task in the list. This is called after an open task's checkbox has been toggled.
   * @param tasks
   */
  toggleTask(tasks: OpenTaskDto[]): void {
    tasks.map(task => task.taskId).forEach(t => {
      const index = this.selectedTasks.indexOf(t);
      if (index === -1) {
        this.selectedTasks.push(t);
      } else {
        this.selectedTasks.splice(index, 1);
      }
    });
  }

  /**
   * Returns boolean, indicating if a task (OpenTaskListDto) is visible, based on the selected filter categories
   * and the type of the first task in the tasks array of the OpenTaskListDto.
   * @param task The open task list to check.
   */
  isTaskVisible(task: OpenTaskListDto): boolean {
    let result = false;
    switch (task.tasks[0].name) {
    case 'SURVEY':
    case 'SURVEY_CORRECTION':
      result = this.selectedCategories.includes('SURVEYOR');
      break;
    case 'INITIAL_PROPOSAL':
    case 'ETB':
      result = this.selectedCategories.includes('CUSTODY_DEALER');
      break;
    case 'FOLLOWUP_PROPOSAL':
      result = this.selectedCategories.includes('FOLLOWUP_PROPOSAL');
      break;
    case 'BUY_NOW':
      result = this.selectedCategories.includes('BUY_NOW');
      break;
    }

    if (result === false) {
      task.tasks.map(taskEntry => taskEntry.taskId).forEach(t => {
        const index = this.selectedTasks.indexOf(t);
        if (index !== -1) {
          this.selectedTasks.splice(index, 1);
        }
      });
    }

    return result;
  }

  /**
   * Returns boolean stating, if a task is selected or not.
   * @param tasks The tasks to check.
   */
  isSelected(tasks: OpenTaskDto[]): boolean {
    return tasks.map(task => (this.selectedTasks.indexOf(task.taskId) !== -1))
      .reduce((a, b) => a && b, true);
  }

  /**
   * This method is executed when the "Select/Deselect All" checkbox has been toggled.
   * It selects or deselects all items.
   */
  toggleSelectAll(): void {
    if (this.filterCategories.length === this.selectedCategories.length) {
      this.selectedCategories = [];
    } else {
      this.selectedCategories = [...this.filterCategories];
    }
  }

  /**
   * Toggles a filter category.
   * @param category The filter category to toggle
   */
  toggleFilterCategory(category: string): void {
    const index = this.selectedCategories.indexOf(category);
    if (index > -1) {
      this.selectedCategories.splice(index, 1);
    } else {
      this.selectedCategories.push(category);
    }
  }

  /**
   * Urge the selected open tasks.
   * @param taskIds The selected open tasks
   */
  urgeOpenTasks() {
    this.urgeButtonDisabled = true;
    this.vehicleMaintenanceService.urgeOpenTasks(this.selectedTasks).subscribe((openTasks) => {
      this.openTasks = openTasks;
      this.store.dispatch(new UpdateOpenTasksAction());
      this.toastAlertStore.success(this.translateService.instant('vehicle-maintenance.open-tasks.urge-success'));
      this.urgeButtonDisabled = false;
    }, () => {
      this.toastAlertStore.danger(this.translateService.instant('vehicle-maintenance.open-tasks.urge-error'));
      this.urgeButtonDisabled = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
