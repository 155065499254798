@if (activeCategory !== 'LONGTIME_PENDING' && (userInfo?.accredited !== false || channel !== 'PIA')) {
  <ucs-default-footer
    [offer]="offerData"
    [channel]="channel"
    [vehicle]="vehicle"
    [extraTax]="extraTax"
    [disableBidding]="disableBidding"
    [footerStatus]="footerStatus"
    [hideFooterStatus]="hideFooterStatus"
    [scopeSection]="scopeSection"
    [showOfferLink]="showOfferLink"
    [startPrice]="startPrice"
    [thermalWindowAffected]="isThermalWindowToggleActive && vehicle?.thermalWindowAffected && !approvalFromUser"
    (linkToOfferClicked)="linkToOfferClicked.emit()">
  </ucs-default-footer>
}

@if (activeCategory === 'LONGTIME_PENDING') {
  <ucs-enforced-auction-footer
    [vehicle]="vehicle"
    [enforcedAuctionData]="enforcedAuctionData"
    [displayLogo]="false"></ucs-enforced-auction-footer>
}


@if (userInfo?.accredited === false && channel==='PIA') {
  <ucs-missing-documents-footer>
  </ucs-missing-documents-footer>
}
