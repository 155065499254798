/**
 *  Dialog for initial and follow-up proposal
 */
import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {VehicleMaintenanceService} from '../../service/vehicle-maintenance.service';
import {TranslateService} from '@ngx-translate/core';
import {OfferService} from '../../service/offer.service';
import {isAuctionOfferDto, isOfferDto, isVehicleItemBaseDto} from '../../misc/typeguard';
import {DealerService} from '../../service/dealer.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ucsDeepCopy} from '../../misc/utils';
import {takeUntil} from 'rxjs/operators';
import {SystemSettingsService} from '../../service/system-settings.service';
import {ToastAlertSignalStore} from '../../store/alert/toast-alert.signal-store';

@Component({
  selector: 'ucs-proposal-dialog',
  templateUrl: './proposal-dialog.component.html',
  styleUrls: ['./proposal-dialog.component.scss']
})

export class ProposalDialogComponent implements OnInit, OnDestroy {
  @Input() idSuffix: string = '';
  @Input() name: string;
  @Input() followup: boolean;
  @Input() followupSmallBtn: boolean;
  @Input() initial: boolean;
  @Input() custodyDealer: DealerDetailDto;
  @Input() vehicleOrOfferDto: VehicleBaseDto & OfferDto;
  @Input() vehicleExtraTax: ExtraTaxDto;
  @Input() extraTaxHRpaid: boolean;
  @Output() updateProposal = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  manualInput: boolean;
  manualInputValid: boolean;
  vehicleId: number; // for fetching of master data in manual-dealer-input component
  proposalMake: ProposalMakeDto;
  selectCountry: Country;
  popUpPrice: PriceDto;
  dealerAutoCompleteSourceFunction: (string) => Observable<DealerDetailDto[]>;
  inputError = false;
  private isDistributedDomainIndexUpdateEnabled = false;
  private unsubscribe: Subject<void> = new Subject<void>();

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private translate: TranslateService,
              private dealerService: DealerService,
              private vehicleService: VehicleMaintenanceService,
              private translateService: TranslateService,
              private offerService: OfferService,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit() {
    this.dealerAutoCompleteSourceFunction = (filter: string) =>
      this.dealerService.getDealers(filter, ['PBV_CUSTODY'], this.getChannel());
    this.proposalMake = {} as any;
    this.proposalMake.custodyDealerInformation = {} as any;
    this.manualInput = false;
    this.manualInputValid = false;
    this.proposalMake.sendNotification = true;
    this.selectCountry = 'AT';

    if (this.custodyDealer) {
      this.proposalMake.custodyDealerInformation.type = 'UCS_DEALER';
      (<InternalDealerInformationDto>this.proposalMake.custodyDealerInformation).ucsDealerId = this.custodyDealer.id;
    }

    if (isAuctionOfferDto(this.vehicleOrOfferDto)) {
      if (isVehicleItemBaseDto(<VehicleItemBaseDto>(<AuctionOfferDto>this.vehicleOrOfferDto).items[0])) {
        this.vehicleId = (<VehicleItemBaseDto>(<AuctionOfferDto>this.vehicleOrOfferDto).items[0]).vehicleId;
      }
    } else {
      this.vehicleId = (<VehicleBaseDto>this.vehicleOrOfferDto).id;
    }

    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('DISTRIBUTED_DOMAIN_INDEX_UPDATE')
      .pipe(takeUntil(this.unsubscribe)).subscribe(isEnabled => {
        this.isDistributedDomainIndexUpdateEnabled = isEnabled;
      });
  }

  /**
   * Dealer has been selected via auto complete
   * @param $event
   */
  dealerSelected($event: DealerDetailDto) {
    if ($event) {
      this.custodyDealer = $event;
    } else {
      this.custodyDealer = {} as DealerDetailDto;
    }
    const internalDealer = {} as InternalDealerInformationDto;
    internalDealer.type = 'UCS_DEALER';
    internalDealer.ucsDealerId = this.custodyDealer.id;
    this.proposalMake.custodyDealerInformation = internalDealer;
  }

  /**
   * Dealer has been entered manually.
   * @param $event The manually entered Dealer
   */
  dealerEntered($event: ExternalDealerInformationDto) {
    this.proposalMake.custodyDealerInformation = $event;
  }

  changeToInput() {
    this.manualInput = !this.manualInput;
    this.custodyDealer = {} as DealerDetailDto;
    if (this.manualInput) {
      this.proposalMake.custodyDealerInformation = {} as ExternalDealerInformationDto;
      this.proposalMake.custodyDealerInformation.type = 'EXTERNAL_DEALER';
      this.manualInputValid = false;
    } else {
      this.proposalMake.custodyDealerInformation = {} as InternalDealerInformationDto;
      this.proposalMake.custodyDealerInformation.type = 'UCS_DEALER';
    }
  }

  createProposal() {
    if (this.manualInput) {
      this.proposalMake.sendNotification = false;
    }
    if (this.followup) {
      this.proposalMake.price = this.popUpPrice;
      this.offerService.makeFollowupProposal(this.vehicleOrOfferDto.id, this.proposalMake).subscribe(
        () => {
          this.updateProposal.emit();
        },
        (err) => {
          if ((err as HttpErrorResponse).error.code === 'error.mail.recipients') {
            this.toastAlertStore.info(this.translateService.instant('mail.error.no-recipients'));
            this.updateProposal.emit();
          } else {
            this.closed.emit();
          }
        }
      );
    } else {
      this.vehicleService.makeInitialProposal(this.vehicleOrOfferDto.id, this.proposalMake).subscribe({
        next: () => {
          // if enabled we get notified (via websocket) of vehicle update and don't need to manually request an update
          if (!this.isDistributedDomainIndexUpdateEnabled) {
            this.updateProposal.emit();
          }
        },
        error: (err) => {
          this.closed.emit();
        }
      });
    }
  }

  getInputError($event) {
    this.inputError = $event;
  }

  isSubmitButtonDisabled() {
    return (this.manualInput && !this.manualInputValid) ||
      (this.proposalMake.custodyDealerInformation && this.proposalMake.custodyDealerInformation.type === 'UCS_DEALER' &&
        !(<InternalDealerInformationDto>this.proposalMake.custodyDealerInformation).ucsDealerId) || this.inputError;
  }

  getChannel(): DistributionChannel {
    // @ts-ignore this.vehicleOrOfferDto.channel is a false positive
    return isOfferDto(this.vehicleOrOfferDto) ? this.vehicleOrOfferDto.channel.data : this.vehicleOrOfferDto.channel;
  }

  createDeepCopyForFollowup() {
    if (isAuctionOfferDto(this.vehicleOrOfferDto)) {
      if ((<AuctionOfferDto>this.vehicleOrOfferDto).highestBid) {
        this.popUpPrice = ucsDeepCopy(this.vehicleOrOfferDto.highestBid);
      }
    }
  }

  checkExtraTaxPaid() {
    if (this.vehicleOrOfferDto.country === 'HR' && this.extraTaxHRpaid === null) {
      this.toastAlertStore.danger(this.translate.instant('ucs.error.empty-extraTaxPaid.PPMV'));
    }
  }

  protected readonly console = console;

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
