/**
 * Dialog for create a bundle
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {filter, takeUntil} from 'rxjs/operators';
import * as fromRoot from '../../store/app.reducers';
import {Store} from '@ngrx/store';
import {combineLatest, Subject} from 'rxjs';
import * as VehicleMaintenanceActions from '../../store/vehicle-maintenance/vehicle-maintenance.actions';
import {State} from '../../store/user/user.reducers';
import {PageSettings} from '../../model/page-settings.model';
import {OfferService} from '../../service/offer.service';
import {Router} from '@angular/router';
import {SystemSettingsService} from '../../service/system-settings.service';
import {ucsIsNil} from '../../misc/utils';

@Component({
  selector: 'ucs-basket-dialog',
  templateUrl: './basket-dialog.component.html',
  styleUrls: ['./basket-dialog.component.scss']
})
export class BasketDialogComponent implements OnInit, OnDestroy {
  @Input() pageSettings: PageSettings;

  vehicles: VehicleBaseDto[];
  userState: State;
  allVehiclesHaveSameCustodyDealer: boolean;
  allVehiclesAreSoldByMe: boolean;
  transportTooltip: string;
  featureTransport = false;
  isBundleTransportRequestToggleActive: boolean;

  mayCreateBuyNowOffer: boolean;
  mayCreateAuctionOffer: boolean;

  private unsubscribe: Subject<void> = new Subject<void>();

  protected readonly VehicleDetailOfferItemDtos: VehicleDetailDto[] & OfferItemDto[];

  constructor(
    private store: Store<fromRoot.AppState>,
    private translate: TranslateService,
    private offerService: OfferService,
    private router: Router,
    private systemSettings: SystemSettingsService
  ) {
  }

  ngOnInit() {

    let userStateObservable= this.store
      .select(fromRoot.getUserState)
      .pipe(filter(state => !!state));

    let vehicleBasketObservable= this.store
      .select(fromRoot.getMaintenanceVehicleBasket)
      .pipe(filter(basket => !!basket));

    combineLatest([userStateObservable, vehicleBasketObservable]).pipe(takeUntil(this.unsubscribe))
      .subscribe(([userState, vehicleBasket]) => {
        this.userState = userState;
        this.mayCreateBuyNowOffer = this.mayCreateOffer('BUYNOW');
        this.mayCreateAuctionOffer = this.mayCreateOffer('AUCTION');

        this.vehicles = vehicleBasket;
        this.allVehiclesAreSoldByMe = true;
        this.vehicles.forEach(vehicle => {
          if (vehicle.seller.id !== this.userState.userInfo.currentDealerId) {
            this.allVehiclesAreSoldByMe = false;
          }
        });
        this.allVehiclesHaveSameCustodyDealer = this.vehiclesHaveSameCustodyDealer();
      });

    this.systemSettings
      .isSystemFeatureActivatedForChannel('TRANSPORT', this.pageSettings.channel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.featureTransport = value);

    // if active only transport request button is visible
    this.systemSettings
      .isSystemFeatureActivatedForAnyChannel('UCS_414_BUNDLE_TRANSPORT_REQUEST')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isActive => this.isBundleTransportRequestToggleActive = isActive);

  }

  /**
   * Checks whether the current user has permission to create an offer for the current offer type, also taking the
   * current channel into account.
   *
   * @param type the OfferType to check permission for
   * @returns whether the user may create an offer based on current channel and offer type
   */
  mayCreateOffer(type: OfferType): boolean {
    if (type === 'BUYNOW') {
      return (this.userState.isDevPoi || this.userState.isSupportPoi ||
        this.hasPiaBuyNowRoles() ||
        this.hasPbBuyNowRoles() ||
        this.hasAllUcBuyNowRoles() ||
        this.hasDinBilBuyNowRoles() ||
        this.hasVgrxBuyNowRoles()
      );
    } else if (type === 'AUCTION') {
      return (this.userState.isDevPoi || this.userState.isSupportPoi ||
        this.hasPiaAuctionRoles() ||
        this.hasPbAuctionRoles() ||
        this.hasAllUCAuctionRoles() ||
        this.hasDinBilAuctionRoles() ||
        this.hasVgrxAuctionRoles()
      );
    }

    return false;
  }

  private hasAllUCAuctionRoles() {
    return this.pageSettings.channel === 'ALL_UC' && (this.userState.isAuctionSellerAllUc || this.userState.isAdminAllUc);
  }

  private hasPbAuctionRoles() {
    return this.pageSettings.channel === 'PB' && (this.userState.isAuctionSellerPb || this.userState.isAdminPb);
  }

  private hasPiaAuctionRoles() {
    return this.pageSettings.channel === 'PIA' && (this.userState.isAuctionSellerPia || this.userState.isAdminPia);
  }

  private hasDinBilAuctionRoles() {
    return this.pageSettings.channel === 'DIN_BIL' && (this.userState.isAuctionSellerDinBil || this.userState.isAdminDinBil);
  }

  private hasVgrxAuctionRoles() {
    return this.pageSettings.channel === 'VGRX' && (this.userState.isAuctionSellerVgrx || this.userState.isAdminVgrx);
  }

  private hasAllUcBuyNowRoles() {
    return this.pageSettings.channel === 'ALL_UC' && (this.userState.isBuyNowSellerAllUc || this.userState.isAdminAllUc);
  }

  private hasPbBuyNowRoles() {
    return this.pageSettings.channel === 'PB' && (this.userState.isBuyNowSellerPb || this.userState.isAdminPb);
  }

  private hasPiaBuyNowRoles() {
    return this.pageSettings.channel === 'PIA' && (this.userState.isBuyNowSellerPia || this.userState.isAdminPia);
  }

  private hasDinBilBuyNowRoles() {
    return this.pageSettings.channel === 'DIN_BIL' && (this.userState.isBuyNowSellerDinBil || this.userState.isAdminDinBil);
  }

  private hasVgrxBuyNowRoles() {
    return this.pageSettings.channel === 'VGRX' && (this.userState.isBuyNowSellerVgrx || this.userState.isAdminVgrx);
  }

  /**
   * Creates an offer with the passed type, both single and bundle offer.
   *
   * @param type the offer type of the offer to create
   */
  createOffer(type: OfferType) {
    const createOfferDto = {vehicleIds: [], offerType: type} as CreateOfferDto;
    this.vehicles.forEach(vehicle => {
      createOfferDto.vehicleIds.push(vehicle.id);
    });
    // on success navigate to the newly created offer's maintenance detail page
    this.offerService.createOffer(createOfferDto).subscribe(offerId => {
      if (offerId) {
        this.router.navigateByUrl('/maintenance/offer/' + offerId);
        this.store.dispatch(new VehicleMaintenanceActions.ResetVehicleBasketAction()); // reset basket as offer has been created
      }
    });
  }

  changeCheck(vehicle: VehicleBaseDto) {
    this.store.dispatch(new VehicleMaintenanceActions.DeleteVehicleFromBasketAction(vehicle));
  }

  /**
   * Check if all vehicles have the same custody dealer.
   * @returns {boolean} True, if all the vehicles have the same custody dealer, false otherwise.
   */
  vehiclesHaveSameCustodyDealer(): boolean {
    let firstCustodyDealerId: number;
    if (this.vehicles) {
      if (this.vehicles.length === 1) {
        this.transportTooltip = ''; // no tooltip needed since button is good to go (i.e. not disabled)
        return true;
      }
      if (this.vehicles.length > 1 && this.vehicles[0].dealerInformation?.id) {
        firstCustodyDealerId = this.vehicles[0].dealerInformation?.id;
        if (this.vehicles.filter(v => v.dealerInformation?.id !== firstCustodyDealerId).length > 0) {
          // tooltip explanation because button is disabled
          this.translate.get('basket.tooltip-custody-dealer')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(t => {
              this.transportTooltip = t;
            });
          return false;
        } else {
          this.transportTooltip = ''; // no tooltip needed since button is good to go (i.e. not disabled)
          return true;
        }
      } else {
        // tooltip explanation because button is disabled
        this.translate.get('basket.tooltip-custody-dealer')
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(t => {
            this.transportTooltip = t;
          });
        return false;
      }
    }
  }

  getTransportRequests() {
    return this.vehicles.flatMap(v => v.transportRequests).filter(request => !ucsIsNil(request));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
