import {Component, inject, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {first} from 'rxjs/operators';
import {State} from '../../../store/user/user.reducers';
import {PricePipe} from '../../../pipe/price.pipe';
import {EnforcedAuctionService} from '../../../service/enforced-auction.service';
import {SpinnerService} from '../../../service/spinner.service';
import {TranslateService} from '@ngx-translate/core';
import {ucsIsNil} from '../../../misc/utils';
import {ToastAlertSignalStore} from '../../../store/alert/toast-alert.signal-store';

@Component({
  selector: 'ucs-starting-or-offer-price',
  templateUrl: './starting-or-offer-price.component.html',
  styleUrls: ['./starting-or-offer-price.component.scss']
})
export class StartingOrOfferPriceComponent implements OnInit {
  @Input() startingPrice: PriceDto;
  @Input() catalogItemId: number;
  @Input() vin: string;
  @Input() itemStatus: EnforcedAuctionItemStatus;
  @Input() isAdmin: boolean;
  @Input() vatType: VatType;
  @Input() offerData: LatestOfferDataDto;
  EUR_TO_CENT_FACTOR = 100;
  netToGrossFactorAT: number; // initialized based on vat type, used to calculate between net and gross price
  userState: State;
  enterKeyPressed: boolean = false;

  protected readonly ucsIsNil = ucsIsNil;

  readonly toastAlertStore = inject(ToastAlertSignalStore);

  constructor(private store: Store<fromRoot.AppState>, private pricePipe: PricePipe, private spinnerService: SpinnerService,
              private enforcedAuctionService: EnforcedAuctionService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.store
      .select(fromRoot.getUserState)
      .pipe(first())
      .subscribe(userState => {
        if (userState) {
          this.userState = userState;
        }
      });

    // Enforced Auction MVP:
    // currently enforced auction is only used in AT and there are no other taxes applied, it is a simple 20% change
    // for vat type STANDARD (and no change for vat type DIFFERENTIAL)
    // if needed in the future, we could also query price calculation from backend via REST (as we do with regular
    // bids)
    this.netToGrossFactorAT = 'STANDARD' === this.vatType ? 1.2 : 1;
  }

  updateStartingPrice(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!this.enterKeyPressed) {
      if (input.value === '') {
        input.value = this.pricePipe.transform(this.startingPrice.gross, null, 'de', '1.0-0');
      } else {
        const cleanedValue = input.value.replace(/[^\d,]/g, '');
        this.editStartingPrice(cleanedValue);
      }
    }
    // remove focus from Enter, set it to Blur, but validate to avoid triggering it twice.
    if (event instanceof KeyboardEvent && event.key === 'Enter') {
      this.enterKeyPressed = true;
      input.blur();
    } else {
      this.enterKeyPressed = false;
    }
  }

  private editStartingPrice(cleanedValue: string) {
    const updatedStartingPrice = {
      ...this.startingPrice,
      net: Number(cleanedValue) / this.netToGrossFactorAT * this.EUR_TO_CENT_FACTOR,
      gross: Number(cleanedValue) * this.EUR_TO_CENT_FACTOR
    };
    if ( this.startingPrice.gross === updatedStartingPrice.gross) {
      return;
    }
    this.spinnerService.spinner(this.enforcedAuctionService.editStartingPrice(this.catalogItemId, updatedStartingPrice))
      .subscribe(
        () => {
          this.toastAlertStore.success( this.translateService.instant('vehicle.enforcedAuction.startingPrice-update') + this.vin);
        },
        (error) => {
          this.toastAlertStore.success( this.translateService.instant('vehicle.enforcedAuction.startingPrice-update-fail') + this.vin);
        }
      );
  }
}
